import {
  Box,
  Heading,
  HStack,
  IconButton,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Ability } from "game-data";
import { ReactElement, useEffect, useState } from "react";
import { AbilityCard } from "./AbilityCard";
import { BiSolidRightArrow, BiSolidLeftArrow } from "react-icons/bi";
import { GiMagnifyingGlass } from "react-icons/gi";
import { useWindowDimensions } from "ui/hooks";
interface AbilityCardHandProps {
  abilities: Ability[];
  onSelectAbility: (ability: Ability) => void;
}

export function AbilityCardHandFan({
  abilities,
  onSelectAbility,
}: AbilityCardHandProps) {
  const { width } = useWindowDimensions();
  const occupiedWidth = width * 0.6;
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const overlapX = 250 - occupiedWidth / abilities.length;
  const offsetX = occupiedWidth * 0.25 + 100 + overlapX;
  return (
    <Box
      pt="20px"
      pb="40px"
      h="650px"
      // w="100%"
      w={occupiedWidth}
      alignItems={"center"}
      justifyContent={"center"}
      display="flex"
      overflowY="visible"
      overflowX={"visible"}
      pl={`${100 + offsetX}px`}
      // ml="100px"
      pr={"80px"}
    >
      {abilities.map((ability, index) => {
        const rotation = -45 + index * (90 / (abilities.length - 1));
        const offsetY =
          100 + Math.sin((rotation + 45) * 2 * (Math.PI / 180)) * -100; //30 * Math.abs(index - (abilities.length - 1) / 2);
        return (
          <AbilityCard
            ability={ability}
            key={index}
            index={index}
            offsetX={offsetX}
            overlapX={overlapX}
            offsetY={offsetY}
            // offsetY={30 * Math.abs(index - (abilities.length - 1) / 2)}
            rotation={rotation}
            onHover={(state) => {
              if (state) {
                setSelectedIndex(index);
              } else {
                setSelectedIndex(undefined);
              }
            }}
            selectedIndex={selectedIndex}
            select={(index: number) => {
              onSelectAbility(abilities[index]);
              setSelectedIndex(index);
            }}
          />
        );
      })}
    </Box>
  );
}

export function AbilityCardHandFlat({
  abilities,
  onSelectAbility,
}: AbilityCardHandProps) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { isMobile, width } = useWindowDimensions();
  const overlapX = 0;
  useEffect(() => {
    onSelectAbility(abilities[selectedIndex]);
  }, [selectedIndex]);
  return (
    <>
      <Box
        pl={isMobile ? "" : 5}
        pr={isMobile ? "" : 5}
        h={"450px"}
        w=""
        alignItems={"center"}
        justifyContent={"center"}
        display="flex"
        maxW="80vw"
        overflowX={"scroll"}
        overflowY="visible"
      >
        <IconButton
          variant={"outline"}
          colorScheme={"white"}
          h="200px"
          aria-label="view previous ability"
          icon={<BiSolidLeftArrow />}
          onClick={() => {
            setSelectedIndex(
              selectedIndex > 0
                ? (selectedIndex - 1) % abilities.length
                : abilities.length - 1
            );
          }}
          zIndex="overlay"
        />
        <SimpleGrid
          columns={isMobile ? (width < 400 ? 1 : 3) : 5}
          spacingX="-200px"
        >
          {!isMobile && (
            <AbilityCard
              overlapX={overlapX}
              ability={
                //show two previous
                selectedIndex >= 2
                  ? abilities[selectedIndex - 2]
                  : abilities[abilities.length - 2 + selectedIndex]
              }
              index={
                selectedIndex >= 2
                  ? selectedIndex - 2
                  : abilities.length - 2 + selectedIndex
              }
              selectedIndex={selectedIndex}
            />
          )}
          {width > 400 && (
            <AbilityCard
              overlapX={overlapX}
              ability={
                abilities[
                  selectedIndex >= 1 ? selectedIndex - 1 : abilities.length - 1
                ]
              }
              index={
                selectedIndex >= 1 ? selectedIndex - 1 : abilities.length - 1
              }
              selectedIndex={selectedIndex}
            />
          )}
          <AbilityCard
            overlapX={overlapX}
            ability={abilities[selectedIndex]}
            index={selectedIndex}
            selectedIndex={selectedIndex}
          />
          {width > 400 && (
            <AbilityCard
              zIndex={2}
              overlapX={0}
              ability={
                abilities[
                  selectedIndex < abilities.length - 1 ? selectedIndex + 1 : 1
                ]
              }
              index={
                selectedIndex < abilities.length - 1 ? selectedIndex + 1 : 1
              }
              selectedIndex={selectedIndex}
            />
          )}
          {!isMobile && (
            <AbilityCard
              zIndex={1}
              overlapX={overlapX}
              ability={
                //show two next
                selectedIndex < abilities.length - 2
                  ? abilities[selectedIndex + 2]
                  : abilities[selectedIndex - abilities.length + 2]
              }
              index={
                selectedIndex < abilities.length - 2
                  ? selectedIndex + 2
                  : selectedIndex - abilities.length + 2
              }
              selectedIndex={selectedIndex}
            />
          )}
        </SimpleGrid>
        <IconButton
          variant={"outline"}
          colorScheme={"white"}
          h="200px"
          aria-label="view next ability"
          icon={<BiSolidRightArrow />}
          onClick={() => {
            setSelectedIndex((selectedIndex + 1) % abilities.length);
          }}
          zIndex="overlay"
        />
        {/* <IconButton
        variant={"ghost"}
        icon={<GiMagnifyingGlass />}
        aria-label={"Inspect ability"}
        transition={"all 0.5s ease"}
        colorScheme={"white"}
        onClick={() => {}}
        _hover={{
          fontSize: "30px",
          transition: "all 0.7s ease",
        }}
      /> */}
      </Box>
      <Text textAlign={"center"} fontSize="35px" display={"block"}>
        {selectedIndex + 1} / {abilities.length}
      </Text>
    </>
  );
}
