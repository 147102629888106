import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const highlightVariant = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    transitionProperty: "common",
    transitionDuration: "normal",
    letterSpacing: "wider",
    fontWeight: "light",
    bg: `${c}.600`,
    color: "white",
    _hover: {
      bg: `${c}.400`,
    },
  };
});

export const buttonStyle = defineStyleConfig({
  defaultProps: {
    colorScheme: "darkGold",
    variant: "highlight",
  },

  variants: { highlight: highlightVariant },
});
