import { Button } from "@chakra-ui/react";

interface DialogueChoiceButtonProps {
  key: number;
  choiceKey: string;
  text: string;
  onClick: (key: string) => void;
}
const DialogueChoiceButton = ({
  key,
  choiceKey,
  text,
  onClick,
}: DialogueChoiceButtonProps) => {
  return (
    <Button key={key} onClick={() => onClick(choiceKey)}>
      {text}
    </Button>
  );
};
export default DialogueChoiceButton;
