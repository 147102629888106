import {
  useDisclosure,
  useBoolean,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  Icon,
  DrawerBody,
  DrawerFooter,
  Text,
  Tooltip,
  IconButton,
  DrawerCloseButton,
  Collapse,
  Button,
  Heading,
  HStack,
  Stack,
} from "@chakra-ui/react";
import { createContext, useState, useContext } from "react";
import {
  MdOutlineInvertColorsOff,
  MdOutlineInvertColors,
  MdOpenInFull,
  MdCloseFullscreen,
  MdList,
} from "react-icons/md";
import { GiBookmarklet, GiBookmark } from "react-icons/gi";
import { Glossary, parseGlossaryTerms } from ".";
import { GlossaryTerm } from "./glossary-term";
import useWindowDimensions from "ui/hooks/useWindowDimensions";

interface ContextType {
  inspectTerm: (term?: GlossaryTerm) => void;
  onlyColorIcons?: boolean;
  isOpen: boolean;
}

export const GlossaryContext = createContext<ContextType>({
  inspectTerm: () => {},
  onlyColorIcons: false,
  isOpen: false,
});

export const GlossaryContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [glossaryTerm, setGlossaryTerm] = useState<GlossaryTerm>();
  const [coloredWords, setColoredWords] = useBoolean();
  const [fullScreen, setFullScreen] = useBoolean();
  const [focusDefinition, setFocusDefinition] = useBoolean(true);
  const { isMobile } = useWindowDimensions();
  function inspectTerm(term?: GlossaryTerm) {
    setGlossaryTerm(term);
    onOpen();
  }
  return (
    <GlossaryContext.Provider
      value={{
        inspectTerm,
        onlyColorIcons: coloredWords,
        isOpen,
      }}
    >
      <Drawer
        placement={"left"}
        onClose={onClose}
        isOpen={isOpen}
        size={isMobile ? "fulle" : fullScreen ? "full" : "lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            fontSize={"30px"}
            borderBottomWidth="1px"
            letterSpacing={"wider"}
            fontFamily="clear"
          >
            {" "}
            <Text fontSize={"20px"}>Learn more about </Text>
            <HStack justifyContent={"start"} mr="30px">
              {glossaryTerm ? (
                <Text color={glossaryTerm.color}>
                  {glossaryTerm.name} <Icon as={glossaryTerm.icon} />
                </Text>
              ) : (
                <Text>{"Select a Term below"}</Text>
              )}
              <Tooltip
                label={
                  focusDefinition
                    ? "Hide the definition"
                    : "Expand the definition."
                }
                openDelay={300}
              >
                <IconButton
                  variant={"ghost"}
                  colorScheme="white"
                  onClick={() => {
                    setFocusDefinition.toggle();
                    // onClose();
                  }}
                  _hover={{
                    borderColor: "white",

                    borderBottomWidth: "3px",
                    // borderRightWidth: "3px",
                    // borderWidth: "2px",
                  }}
                  icon={focusDefinition ? <GiBookmarklet /> : <GiBookmark />}
                  aria-label={""}
                />
              </Tooltip>
            </HStack>
          </DrawerHeader>
          <DrawerBody h={"100%"}>
            <Collapse in={focusDefinition} startingHeight={"10vh"}>
              <Text
                transition={"all 0.5s ease"}
                fontFamily={"glossary"}
                fontSize="20px"
                overflowY={"scroll"}
                h={focusDefinition ? "65vh" : "10vh"}
              >
                {glossaryTerm
                  ? parseGlossaryTerms(glossaryTerm.description)
                  : "Select a term to learn more."}
              </Text>
            </Collapse>
            <Heading
              w="100%"
              fontFamily={"glossary"}
              as={Button}
              onClick={() => {
                setFocusDefinition.toggle();
              }}
            >
              <Icon as={MdList} />
              More Terms:
            </Heading>
            <Collapse in={!focusDefinition}>
              <Glossary h={"60vh"} />
            </Collapse>
          </DrawerBody>
          <DrawerFooter>
            <Tooltip
              label="Removes the color if the keywords are hard to read."
              openDelay={300}
            >
              <IconButton
                onClick={setColoredWords.toggle}
                icon={
                  coloredWords ? (
                    <MdOutlineInvertColorsOff />
                  ) : (
                    <MdOutlineInvertColors />
                  )
                }
                aria-label={""}
              />
            </Tooltip>
            {!isMobile && (
              <Tooltip label="Expand the glossary tab." openDelay={300}>
                <IconButton
                  onClick={() => {
                    setFullScreen.toggle();
                    // onClose();
                  }}
                  icon={fullScreen ? <MdCloseFullscreen /> : <MdOpenInFull />}
                  aria-label={""}
                />
              </Tooltip>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      {children}
    </GlossaryContext.Provider>
  );
};
export const useGlossaryContext = () => {
  const context = useContext(GlossaryContext);
  if (context === undefined) {
    throw new Error(
      "useGlossaryContext must be used within a GlossaryContextProvider"
    );
  }
  return context;
};
