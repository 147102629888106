import { Box, Button, Text } from "@chakra-ui/react";

interface TraitDisplayProps {
  trait: any;
}
export default function TraitDisplay({ trait }: TraitDisplayProps) {
  return (
    <Box>
      <Text>{trait.name}</Text>
      <Button
        onClick={() => {
          console.log(trait);
        }}
      >
        More Details
      </Button>
    </Box>
  );
}
