import { GameControllerContextProvider } from "./components/contexts/GameControllerContext";
// import "@fontsource/bangers";
// import "@fontsource/luckiest-guy";
import "@fontsource/anton";
import "@fontsource/dela-gothic-one";
import {
  Box,
  Button,
  ChakraBaseProvider,
  IconButton,
  Img,
  useDisclosure,
} from "@chakra-ui/react";
import { ConnectionContextProvider } from "./components/contexts/ConnectionContext";
import { webControllerTheme } from "ui/styles";

import config from "./config";
import { GlossaryContextProvider } from "./components/glossary";
import { MainToolbar, MainTabs } from "./components/global-components";
function App() {
  return (
    <ChakraBaseProvider theme={webControllerTheme}>
      <Box layerStyle={"base"}>
        <ConnectionContextProvider>
          <GameControllerContextProvider>
            <GlossaryContextProvider>
              <MainToolbar />
              <MainTabs />{" "}
            </GlossaryContextProvider>
          </GameControllerContextProvider>
        </ConnectionContextProvider>{" "}
      </Box>
    </ChakraBaseProvider>
  );
}

export default App;
