import { useGameControllerContext } from "../contexts/GameControllerContext";
import { ChoiceInfoPanel } from "./ChoiceInfoPanel";
import { useState, useEffect } from "react";
//import { BsHourglassSplit } from "react-icons/bs";
//import { GiFire, GiAxeSword } from "react-icons/gi";
import { Grid, GridItem, Heading, HStack } from "@chakra-ui/react";
import CombatChoiceButtonsDisplay from "./CombatChoiceButtonDisplay";
import { useConnectionContext } from "../contexts/ConnectionContext";
import { Choice } from "../../../../../packages/server-types/src";
import AbilitySelectionDisplay from "./AbilitySelectionDisplay";
import { testAbilities } from "./testAbilities";
export function CombatControlsPanel() {
  const {
    gameContext,
    choiceContext,
    sendChoice: sendWSChoice,
  } = useConnectionContext();
  const [selectedChoice, setSelectedChoice] = useState<Choice | null>(null);
  const [expanded, setExpanded] = useState<Choice[]>([]);
  const [hasChoices, setHasChoices] = useState(false);
  const [hoverChoice, setHoverChoice] = useState<Choice | null>(null);
  // useEffect(() => {
  //   if (choiceContext?.playerChoices) {
  //     setHasChoices(true);
  //   } else {
  //     setHasChoices(false);
  //   }
  // });

  return (
    <>
      <AbilitySelectionDisplay
        abilitiesInDeck={testAbilities.slice(5, 10)}
        abilitiesInHand={testAbilities.slice(0, 5)}
        abilitiesInDiscard={testAbilities.slice(10)}
      />
    </>
  );
}
