import React, { createContext, useState, useEffect, useContext } from "react";
import { useConnectionContext } from "./ConnectionContext";
import { GameControllerLayout } from "../../types/controller-generation";
export interface MainControlPanelSettings {
  characterCreation: boolean;
  combat: boolean;
  worldMap: boolean;
  location: boolean;
}
export const defaultMainControllerPanel: MainControlPanelSettings = {
  characterCreation: false,
  combat: false,
  worldMap: false,
  location: false,
};
interface ContextType {
  controllerLayout: GameControllerLayout | null;
  setLayoutName: (name: string) => void;
  currentControllerPanel: MainControlPanelSettings;
  setCurrentControllerPanel: (settings: MainControlPanelSettings) => void;
}

export const GameControllerContext = createContext<ContextType>({
  controllerLayout: null,
  setLayoutName: () => {},
  currentControllerPanel: defaultMainControllerPanel,
  setCurrentControllerPanel: () => {},
});

export const GameControllerContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // const [gameControllerContext, setGameControllerContext] =
  //   useState<GameController>({});
  const { choiceContext } = useConnectionContext();
  const [currentControllerPanel, setCurrentControllerPanel] =
    useState<MainControlPanelSettings>(defaultMainControllerPanel);
  const [controllerLayout, setControllerLayout] =
    useState<GameControllerLayout | null>(null);
  const [layoutName, setLayoutName] = useState<string>("controller");
  // useEffect(() => {
  //   fetch("testController.json", {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   }).then((response) => {
  //     response.json().then((data) => {
  //       console.log(data);
  //       setControllerLayout(data[layoutName]);
  //     });
  //   });
  // }, []);

  useEffect(() => {
    if (choiceContext && choiceContext.context == "character_creation") {
      setCurrentControllerPanel({
        ...defaultMainControllerPanel,
        characterCreation: true,
      });
    }
  }, [choiceContext]);
  // useEffect(() => {
  //   if (receivedWSMessageEvent) {
  //     receivedWSMessageEvent.addListener(
  //       "CHOICE_CONTEXT",
  //       parseChoiceContextMessage
  //     );
  //     receivedWSMessageEvent.addListener(
  //       "GAME_CONTEXT",
  //       parseGameContextMessage
  //     );
  //   }
  //   return () => {
  //     receivedWSMessageEvent?.removeListener(
  //       "CHOICE_CONTEXT",
  //       parseChoiceContextMessage
  //     );
  //     receivedWSMessageEvent?.removeListener(
  //       "GAME_CONTEXT",
  //       parseGameContextMessage
  //     );
  //   };
  // }, [receivedWSMessageEvent]);

  function setOnlyPropertyToTrue(property: string) {
    setCurrentControllerPanel({
      ...defaultMainControllerPanel,
      [property]: true,
    });
  }
  //TODO add a paused screen when Host is disconnected
  return (
    <GameControllerContext.Provider
      value={{
        controllerLayout,
        setLayoutName,
        currentControllerPanel,
        setCurrentControllerPanel,
      }}
    >
      {children}
    </GameControllerContext.Provider>
  );
};
export const useGameControllerContext = () => {
  const context = useContext(GameControllerContext);
  if (context === undefined) {
    throw new Error("useGameContext must be used within a GameContextProvider");
  }
  return context;
};
