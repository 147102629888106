import { IconType } from "react-icons";
import { FaRunning, FaHandSparkles, FaQuestion } from "react-icons/fa";
import {
  GiShoulderArmor,
  GiSmallFire,
  GiBrokenHeart,
  GiBodyBalance,
  GiMagnifyingGlass,
  GiMuscleUp,
  GiStrong,
  GiFireSilhouette,
  GiHeartInside,
  GiSkills,
  GiSprint,
  GiHearts,
  GiHourglass,
  GiSwordman,
  GiStack,
  GiProgression,
  GiSwordBrandish,
  GiPlayerNext,
  GiBellShield,
  GiDrop,
  GiMisdirection,
  GiDropWeapon,
  GiBattleGear,
  GiCrackedShield,
} from "react-icons/gi";
import { GlossaryTerm } from "./glossary-term";

export const glossary: { [key: string]: GlossaryTerm } = {
  braves: {
    name: "B.R.A.V.E.S.",
    color: "white",
    tooltip: "The six main stats of the adventurer.",
    icon: GiSkills,
    description:
      "<braves>B.R.A.V.E.S.</braves> is an acronym for the six stats of the adventurer:<br/><brawn>Brawn</brawn>, <resistance>Resistance</resistance>, <attunement>Attunment</attunement>, <virtuosity>Virtuosity</virtuosity>, <energy>Energy</energy>, and <speed>Speed</speed>.",
    grouping: "stats",
  },
  brawn: {
    name: "Brawn",
    color: "red.600",
    tooltip: "Physical strength of the adventurer.",
    icon: GiMuscleUp,
    description:
      "<brawn>Brawn</brawn> is a measure of how strong you are.<br/><br/>This translates to the amount of <damage>Physical Damage</damage> you deal, but also increases your effectiveness at <armor_break>breaking armor</armor_break>.",
    grouping: "stats/braves",
  },
  resistance: {
    name: "Resistance",
    color: "orange",
    tooltip: "The general toughness of the adventurer.",
    icon: GiHeartInside,
    description:
      "<resistance>Resistance</resistance> is a stat that reduces the amount of <damage>Damage</damage> taken. It affects how much <armor>Armor</armor> you get from <item>your equipment</item>, how quickly <effect>Effects</effect> wear off, and how likely you are to be affected by <effect>Effects</effect>",
    grouping: "stats/braves",
  },
  attunement: {
    name: "Attunement",
    color: "green.500",
    tooltip: "The ability to use magic.",
    icon: GiFireSilhouette,
    description:
      "In combat, <attunement>Attunement</attunement> is a stat that increases the amount of <magic>Magic Damage</magic> dealt.",
    grouping: "stats/braves",
  },
  virtuosity: {
    name: "Virtuosity",
    color: "purple.400",
    tooltip: "The ability to perform complex actions in and out of combat.",
    icon: GiBodyBalance,
    description:
      "<virtuosity>Virtuosity</virtuosity> is a stat that determines how quickly you learn <skill>Skills</skill>.<br/><br/>In combat, <virtuosity>Virtuosity</virtuosity> determines how many <action>abilities</action> you have access to at a time.",
    grouping: "stats/braves",
  },
  energy: {
    icon: FaRunning,
    color: "yellow",
    name: "Energy",
    tooltip: "Energy is used to perform actions",
    description:
      "<energy>Energy</energy> is one of the most direct transfer from the stat to gameplay effects.<br/><br/>During combat, <energy>Energy</energy> is used to perform <action>Actions</action>. Your <energy>Max Energy</energy> is the amount of <energy>total Energy</energy> your character can have.<br/>Each turn a certain amount of <energy>Energy</energy> is replenished.<br/><br/><energy>Energy</energy> also contributes to blocking <magic>non-physical</magic> attacks by contributing to an <barrier>energy barrier</barrier>.",
    grouping: "stats/braves",
  },
  speed: {
    icon: GiSprint,
    color: "blue.500",
    name: "Speed",
    tooltip: "Speed determines turn order and affects factors like Dodge.",
    description:
      "<speed>Speed</speed> contributes to how quickly your character moves and reacts to the world. <br/><br/>In combat, <speed>Speed</speed> determines the <priority>order of turns</priority>. The higher your <speed>Speed</speed>, the sooner you can act.",
    grouping: "stats/braves",
  },
  health: {
    icon: GiHearts,
    color: "red",
    name: "Health",
    tooltip: "Health is depleted by damage",
    description:
      "<health>Health</health> is depleted by <damage>Damage</damage>. When it hits zero, you <death>die</death> (or sustain a <injury>serious injury</injury>).",
    grouping: "stats/combat",
  },
  armor: {
    name: "Armor",
    color: "#A4A6A6",
    tooltip: "Physical Damage mitigation",
    icon: GiShoulderArmor,
    description:
      "<armor>Armor</armor> is a stat that reduces the amount of damage taken from <damage>Damage</damage>.",
    grouping: "stats/combat",
  },

  effect: {
    name: "Effect",

    tooltip: "An effect is a persistent state that affects the character",
    description:
      "An <effect>Effect</effect> is any state that affects a character.<br/>This includes Buffs, Debuffs, and Status Effects, but it is also applied by <trait>Traits</trait> and <item>gear</item>",
    icon: GiStack,
    grouping: "effect",
  },
  barrier: {
    name: "Barrier",
    color: "#934DE7",
    tooltip: "Magic Damage mitigation",
    icon: GiBellShield,
    description:
      "<barrier/> is a stat that reduces the amount of damage taken from <magic>Magic Damage</magic>.",
    grouping: "effect",
  },
  bleed: {
    name: "Bleed",
    color: "red",
    tooltip: "Damage per turn that gradually reduces",
    icon: GiDrop,
    description:
      "Losing one stack per location. While in combat lose <max_health/> and <health/> per turn (if <health/> is full, then only <max_health/> will appear to be lost).<br/><br/>Note: Effect on Max Health continues after battle too! (until healed)",
  },
  burn: {
    name: "Burn",
    color: "orange",
    tooltip: "Damage per turn that gradually reduces",
    icon: GiSmallFire,
    description:
      "<burn>Burn Damage</burn> is mitigated by <energy>Energy</energy> and reduces the target's Health. Damage is applied <turn>every turn</turn> and the number of stacks is reduced by one.",
    grouping: "effect",
  },
  confusion: {
    name: "Confusion",
    color: "#E357C0",
    tooltip: "Confusion causes the character to use random abilities",
    icon: GiMisdirection,
    description:
      "<confusion>Confusion</confusion> causes a character to use <ability>Abilities</ability> at random.",
    grouping: "effect",
  },
  disarm: {
    name: "Disarm",
    color: "#E357C0",
    tooltip: "Disarm prevents the character from using equipment abilities.",
    icon: GiDropWeapon,
    description:
      "<disarm>Disarm</disarm> prevents the character from using <item>Equipment</item> <ability>Abilities</ability>.<br/>Certain effects will make you immune to being <disarm>disarmed</disarm>",
    grouping: "effect",
  },
  turn: {
    icon: GiHourglass,

    name: "Turn",
    tooltip: "A turn is a unit of time in combat",
    description:
      "A <turn>Turn</turn> is a unit of time in combat. Each turn, each character queues up their <action>Actions</action>. The order of turns is determined by the <priority>Priority</priority> stat.",
    grouping: "gameplay/combat",
  },
  action: {
    icon: GiSwordman,

    name: "Action",
    tooltip: "An action is a single combat maneuver",
    description:
      "An action is a single combat maneuver. Generally, <turn>Each Turn</turn>, a character can as many <action>Actions</action> as they have <energy>Energy</energy> for (typically using an <ability>Ability</ability>, using an <item>Consumable Item</item> or moving).",
    grouping: "gameplay/combat",
  },

  skill: {
    icon: GiProgression,

    name: "Skill",
    tooltip:
      "A skill is a proficiency that can affect the outcome of your actions",
    description:
      "A <skill>Skill</skill> is a proficiency that affects the effectiveness of your <action>Actions</action>. ",
    grouping: "character",
  },
  ability: {
    icon: GiSwordBrandish,

    name: "Ability",
    tooltip: "An ability is a combat action",
    description:
      "An <ability>Ability</ability> is something that players can do. This can be a simple punch or a complicated spell.<br/><br/> In combat, <ability>Abilities</ability> tend to correspond with a single <action>Action</action>, however some abilities are more complicated and may actually perform multiple actions (such as attacking *and* moving).",
    grouping: "character",
  },
  damage: {
    name: "Damage",
    color: "red",
    tooltip: "Reduces the target's Health. Damage is blocked by armor.",
    icon: GiBrokenHeart,
    description:
      "<damage>Damage</damage> is mitigated by <armor>Armor</armor> and reduces the target's <health>Health</health>. ",
    grouping: "stats/combat",
  },

  magic: {
    icon: FaHandSparkles,
    color: "#CC00CC",
    name: "Magic",
    tooltip: "Magic Damage is not blocked by Armor and depletes Energy",
    description:
      "<magic>Arcane forces</magic> influence far more than just the elements.<br/><br/>In combat, <magic>Magic Damage</magic> is not blocked by <armor>Armor</armor>. Rather, it is blocked by the target's <barrier>Barrier</barrier>.",
    grouping: "stats/combat",
  },
  armor_break: {
    icon: GiCrackedShield,
    color: "#A4A6A6",
    name: "Armor Break",
    tooltip: "Armor Break reduces the target's Armor",
    description:
      "<armor_break>Armor Break</armor_break> reduces the target's <armor/>.",
    grouping: "effect",
  },
  priority: {
    icon: GiPlayerNext,
    name: "Priority",
    tooltip: "Priority determines the order of actions within a turn",
    description:
      "<priority>Priority</priority> determines the order of <action>Actions</action> within a <turn/>. The higher your <speed/>, the higher your priority tends to be.<br/>Other factors include the <ability/>'s inherent priority or any effects from <item>Items</item> or <trait>Traits</trait>.",
    grouping: "gameplay/combat",
  },
  item: {
    icon: GiBattleGear,
    name: "Item",
    tooltip: "Items are equipped to provide traits and abilities",
    description:
      "<item>Items</item> are equipped to provide <trait>Traits</trait> and <ability>Abilities</ability>.<br/><br/>Items can be <consumable/>.",
    grouping: "item",
  },
  default: {
    name: "Missing",
    tooltip: "No Glossary Definition Yet",
    description:
      "This definition will show for all of the terms that I haven't made a UI description for this yet.",
    icon: FaQuestion,
  },
};
