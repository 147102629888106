import { Box, Button, Stack, Text } from "@chakra-ui/react";
import { Choice } from "../../../../../packages/server-types/src";
import { WaitForHost } from "../base";
import { useConnectionContext } from "../contexts/ConnectionContext";
import DialogueChoiceButton from "../game-object-views/DialogueChoiceButton";

interface DialoguePanelProps {
  // prompt: string;
  choices: Choice[];
  // onChoice: (choice: string) => void;
}
const DialoguePanel = ({ choices }: DialoguePanelProps) => {
  const { isHost, sendButtonInput } = useConnectionContext();

  return (
    <Box borderWidth={5}>
      <Text>Dialogue</Text>
      {/* {choices.length == 0 && (
        <Button
          onClick={() => {
            sendButtonInput("dialogue", "continue");
          }}
        >
          Continue
        </Button>
      )} */}
      <Stack>
        {choices.map((choice, index) => {
          if (choice.choiceID == "wait") {
            return <WaitForHost />;
          }
          return (
            <DialogueChoiceButton
              key={index}
              choiceKey={choice.choiceID}
              text={choice.data?.text}
              onClick={() => {
                sendButtonInput("location", "dialogue", choice.choiceID);
              }}
            />
          );
        })}
      </Stack>
    </Box>
  );
};

export default DialoguePanel;
