import { AlertStatus, useToast } from "@chakra-ui/react";
import { createContext, useContext, useEffect, useState } from "react";
import {
  Result,
  WSMessage,
  WSMessageType,
  ControllerData,
  Choice,
  GameContext,
  GameContextMessage,
  ChoiceContext,
  LogMessage,
  ControllerConnectionMessage,
  InputMessage,
  LogMessageType,
  RoomStatusMessage,
  NotificationMessage,
  RoomStatus,
  ControllerLayout,
  ChoiceContextMessage,
} from "server-types";
import config from "../../config/index";
import EventEmitter from "events";

interface ContextType {
  /**
   * Allows other components to listen for incoming messages from the server
   */
  wsEventEmitter: EventEmitter;
  roomStatus: RoomStatus | null;
  joinRoom: (roomCode: string, url?: string) => void;
  leaveRoom: () => void;
  requestConnectController: (controllerKey: string) => void;
  disconnectController: () => void;
  sendChatMessage: (text: string, header?: string) => void;
  /**Soon to be deprecated */
  sendButtonInput: (
    context: string,
    fieldName: string,
    value: string | number
  ) => void;

  /**In general all input should be sent as a choice with a context attached.*/
  sendChoice: (choice: Choice) => void;
  controllerData: ControllerData | null;
  controllerLayout: ControllerLayout | null;

  fullLog: LogMessage[];

  gameContext: GameContext | null;
  choiceContext: ChoiceContext | null;
  choiceContexts: {
    [id: string]: ChoiceContext;
  };
  valueStore: {
    [id: string]: any;
  };
  storeValue: (id: string, value: any) => void;

  isHost: boolean;
  setPlayerName: (name: string) => void;
  playerName: string;
}
export const ConnectionContext = createContext<ContextType>({
  roomStatus: null,
  joinRoom: (roomCode: string, url?: string) => {},
  leaveRoom: () => {},
  requestConnectController: (controllerKey: string) => {},
  disconnectController: () => {},
  wsEventEmitter: new EventEmitter(),
  sendChatMessage: () => {},
  sendButtonInput: () => {},
  sendChoice: () => {},

  controllerLayout: null,
  controllerData: null,
  fullLog: [],

  gameContext: null,
  choiceContexts: {},
  choiceContext: null,
  valueStore: {},
  storeValue: () => {},
  isHost: false,
  setPlayerName: (name: string) => {},
  playerName: "",
});
export const ConnectionContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [ws, setWS] = useState<WebSocket | null>(null);
  const [roomStatus, setRoomStatus] = useState<RoomStatus | null>(null);
  const [gameContext, setGameContext] = useState<GameContext | null>(null);
  const [valueStore, setValueStore] = useState<{
    [id: string]: any;
  }>({});
  const [choiceContexts, setChoiceContexts] = useState<{
    [id: string]: ChoiceContext;
  }>({});

  const [choiceContext, setChoiceContext] = useState<ChoiceContext | null>(
    null
  );
  const [playerName, setPlayerName] = useState<string>("");
  const [receivedWSMessageEventEmitter, setReceivedWSMessageEvent] =
    useState<EventEmitter>(new EventEmitter());
  const [isHost, setIsHost] = useState<boolean>(false);
  const [controllerData, setControllerData] = useState<ControllerData | null>(
    null
  );
  const [controllerLayout, setControllerLayout] =
    useState<ControllerLayout | null>(null);
  const [fullLog, setFullLog] = useState([]);
  const toast = useToast();
  useEffect(() => {
    setReceivedWSMessageEvent(new EventEmitter());
  }, []);

  useEffect(() => {
    if (!controllerData) {
      setGameContext(null);
    } else {
      setIsHost(controllerData.isHost);
    }
  }, [controllerData]);

  useEffect(() => {
    if (!roomStatus) {
      setGameContext(null);
      setChoiceContext(null);
      setChoiceContexts({});
      setFullLog([]);
    }
  }, [roomStatus]);
  async function joinRoom(roomCode: string, url?: string) {
    if (roomStatus) return; //we shouldn't be triggering join room, if we are already in a room

    console.log(`Connecting to:
    Room code: ${roomCode}
    Player Name: ${playerName}
    
    Through address: ${url ? url : config.connectionURL}`);
    if (url) {
      establishWebSocketConnection(
        `${url}/?join=${roomCode}&name=${playerName}`
      );
    } else {
      establishWebSocketConnection(
        `${config.connectionURL}/?join=${roomCode}&name=${playerName}`
      );
    }
  }

  function leaveRoom() {
    if (ws) ws.close();
  }
  function onClose() {
    // localStorage.removeItem("roomCode");
    // localStorage.removeItem("playerName");
    setRoomStatus(null);
    setFullLog([]);
    setControllerLayout(null);
    setChoiceContexts({});
    setControllerData(null);
    toast({
      title: "Left the room",
      description:
        "The connection to the game and server have been closed. Thanks for playing!",
      position: "top",
      status: "info",
      duration: 3000,
    });
    receivedWSMessageEventEmitter.emit("close", null);
    receivedWSMessageEventEmitter.removeAllListeners();
    setWS(null);
  }
  function establishWebSocketConnection(wsURL: string) {
    //TODO pass a query to the server first to see if there is even a valid Room to join
    let ws = new WebSocket(wsURL);

    ws.onerror = ws.onmessage = ws.onopen = ws.onclose = null;
    ws.onerror = (e) => {
      console.error(e);
      toast({
        title: "Connection error",
        description:
          "Sorry, maybe the server is down? You can check the webconsole for more information.",
        position: "top",
        status: "error",
        duration: 800,
      });
      setRoomStatus(null);
      ws.close();
    };
    ws.onopen = function () {};
    ws.onclose = function () {
      onClose();
    };
    ws.onmessage = (event) => {
      const message: WSMessage = JSON.parse(event.data);

      if (message) {
        parseMessage(message);
        console.log(`Received message: ${message.type}`);
        receivedWSMessageEventEmitter.emit(message.type, message);
      } else {
        console.log("Unexpected message: ", event.data);
      }
    };
    setWS(ws);
  }
  /**
   * This only handles the connection relevant/higher level messages
   * Log messages are handled by the Log Display component, and we can update other components separately by having them listen for messages of specific types as well.
   * @param message
   * @returns
   */
  function parseMessage(message: WSMessage) {
    if (!message.type) {
      console.error("No message type!");
      return;
    }
    const { data, header } = message;
    switch (message.type) {
      case "FULL_LOG":
        //all chat and log messages are sent in a bundle and the client can filter accordingly
        if (!data) {
          console.warn(`No data present in the log attached!`);
          return;
        }
        setFullLog(data);
        return;
      case WSMessageType.ROOM:
        parseRoomManagmentMessages(message as RoomStatusMessage);
        return;
      case WSMessageType.CONTROLLER:
        parseControllerManagementMessage(message);
        return;
      case WSMessageType.UPDATE:
        parseUpdateMessage(message);
        return;
      case WSMessageType.GAME_CONTEXT:
        parseGameContextMessage(message as GameContextMessage);
        return;
      case WSMessageType.CHOICE_CONTEXT:
        parseChoiceContextMessage(message as ChoiceContextMessage);
        return;
      case WSMessageType.NOTIFICATION:
        parseNotificationMessage(message as NotificationMessage);
        return;
    }
  }
  function parseRoomManagmentMessages(message: RoomStatusMessage) {
    //type, sender, textData
    const { header, data } = message;
    //console.log(message)
    const { roomCode, hostStatus, status } = data;
    if (status === "OPEN") {
      setRoomStatus(data);
      localStorage.setItem("roomCode", roomCode);
      localStorage.setItem("playerName", playerName);
    }
  }
  function parseNotificationMessage(message: NotificationMessage) {
    const { header, data } = message;
    const { title, text, status } = data;
    toast({
      title: title,
      description: text,
      status: status,
      position: "top-left",
    });
  }
  function parseControllerManagementMessage(message: WSMessage) {
    const { header, data } = message;
    if (header === "CONNECT") {
      setControllerData(data);
    } else if (header === "DISCONNECT") {
      setControllerData(null);
    } else if (header === "CONTROLLER_LAYOUT") {
      const parsed = JSON.parse(data);
      // console.log(parsed);
      const choiceContextList: ChoiceContext[] = parsed.choiceContexts;
      if (!choiceContextList) {
        console.log("No choice contexts");
        return;
      }
      if (choiceContextList.length == 0) {
        return;
      }
      const newMap: { [id: string]: ChoiceContext } = {};

      choiceContextList.forEach((contextObj) => {
        newMap[contextObj.context] = contextObj;
      });
      setChoiceContexts(newMap);
      setControllerLayout(parsed.controllerLayout);
    } else if (header === "UPDATE_CHOICE_CONTEXT") {
      const contextObj: ChoiceContext = JSON.parse(data);
      setChoiceContexts((prevContexts) => ({
        ...prevContexts,
        [contextObj.context]: contextObj,
      }));
    }
  }
  function parseGameContextMessage(message: GameContextMessage) {
    const { header, data } = message;
    const choiceContextList: ChoiceContext[] = data.choiceContexts;
    if (!choiceContextList) {
      console.log("No choice contexts");
      return;
    }
    if (choiceContextList.length == 0) {
      return;
    }
    const newMap: { [id: string]: ChoiceContext } = {};

    choiceContextList.forEach((contextObj) => {
      newMap[contextObj.context] = contextObj;
    });
    setChoiceContexts(newMap);
    setControllerLayout(data.controllerLayout);
  }

  function parseChoiceContextMessage(message: ChoiceContextMessage) {
    const { header, data } = message;
    const contextList: ChoiceContext[] = data;
    if (!contextList || contextList.length == 0) {
      console.log("No choice contexts to update");
      return;
    }
    if (header === "REPLACE") {
      setChoiceContexts({});
    }
    contextList.forEach((context) => {
      setChoiceContexts((prevContexts) => ({
        ...prevContexts,
        [context.context]: context,
      }));
    });
  }
  function parseUpdateMessage(message: WSMessage) {
    const { type, header, data } = message;
    if (!data) {
      console.error(
        `UPDATE ${type} message ${header}: had data that was undefined`
      );
      return;
    }
    if (header === "choice_context") {
      setChoiceContext(data);
      console.log(`Current Choice Context:`, data);
    } else if (header === "game_context") {
      setGameContext(data);
      console.log(
        `Current Game Context:
      Scene Type: ${data?.sceneType}
      Mode: ${data?.mode}`
      );
    }
  }

  function sendChatMessage(text: string, header?: string) {
    if (!ws || ws.readyState === ws.CLOSED) {
      console.error(`Trying to send WS message, but no websocket established!`);
      return;
    }

    if (!roomStatus) {
      console.error(`Trying to send WS message, but no room joined!`);
      return;
    }

    let message: LogMessage = {
      type: WSMessageType.LOG,
      sender: playerName,
      header: header || "none",
      time: new Date().toJSON(),
      data: {
        sender: playerName,
        logMessageType: LogMessageType.CHAT,
        recipient: "ALL",
        text: text,
      },
    };

    ws.send(JSON.stringify(message));
  }
  /**
   * sends a choice object as a JSON string back to the server
   * @param {Choice} choice
   */
  function sendWSChoice(choice: Choice) {
    if (!ws || ws.readyState === ws.CLOSED) {
      console.error(`Trying to send WS message, but no websocket established!`);
      return;
    }
    if (!roomStatus || !controllerData) {
      console.log(`Can't send choice if no Room Info or Controller`);
      return;
    }
    storeValue(choice.context, choice.choiceID);
    //NO errors caught
    let message: InputMessage = {
      type: WSMessageType.INPUT,
      header: "choice",
      sender: playerName,
      controllerKey: controllerData.key,
      data: { choice },
      time: new Date().toJSON(),
    };

    ws.send(JSON.stringify(message));
  }

  //DEPRECATED this is now handled by sendWSChoice
  function sendWSButtonInput(
    context: string,
    key: string,
    value: string | number
  ) {
    //sendWSMessage()
    if (!roomStatus || !controllerData) {
      console.log(`Can't send choice if no Room Info or Controller`);
      return;
    }
  }

  function requestConnectController(controllerKey: string) {
    if (!ws) {
      toast({ title: "Can't connect to server", status: "error" });
      return;
    }
    if (!roomStatus) return;
    const message: ControllerConnectionMessage = {
      type: WSMessageType.CONTROLLER,
      header: "CONNECT",
      data: {
        controllerKey: controllerKey,
        playerName: playerName,
      },
      sender: playerName,
      time: new Date().toJSON(),
    };
    ws.send(JSON.stringify(message));
  }

  function disconnectController() {
    if (!controllerData || !ws) {
      console.log(`Controller is already null!`);
      return;
    }
    if (!roomStatus) {
      return;
    }
    const message: ControllerConnectionMessage = {
      type: WSMessageType.CONTROLLER,
      sender: playerName,
      header: "DISCONNECT",
      data: {
        controllerKey: controllerData.key,
        playerName: playerName,
      },
      time: new Date().toJSON(),
    };
    ws.send(JSON.stringify(message));
    setControllerData(null);
    setControllerLayout(null);
    setChoiceContexts({});
  }

  function storeValue(id: string, value: any) {
    setValueStore((prev) => ({ ...prev, [id]: value }));
  }
  return (
    <ConnectionContext.Provider
      value={{
        roomStatus,
        joinRoom,
        leaveRoom,
        requestConnectController,
        disconnectController,
        wsEventEmitter: receivedWSMessageEventEmitter,
        sendChatMessage,
        sendButtonInput: sendWSButtonInput,
        sendChoice: sendWSChoice,
        choiceContexts,

        valueStore,
        storeValue,

        controllerLayout,
        controllerData: controllerData,
        fullLog,
        gameContext,
        choiceContext,
        isHost,
        setPlayerName,
        playerName,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
};

export const useConnectionContext = () => {
  const context = useContext(ConnectionContext);
  if (context === undefined) {
    throw new Error(
      "useConnectionContext must be used within a ConnectionContextProvider"
    );
  }
  return context;
};
