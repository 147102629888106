import { BoxProps, ButtonGroup, Button, Text } from "@chakra-ui/react";
import { useState } from "react";
interface SelectOneButtonProps extends BoxProps {
  options: string[];
  select: (name: string) => void;
  label?: string;
}
export const SelectOneButton = (props: SelectOneButtonProps) => {
  const { options, label, select } = props;
  const [selected, setSelected] = useState<string>("");
  return (
    <>
      {label ? <Text textAlign={"center"}>{label}</Text> : <> </>}
      <ButtonGroup w="100%">
        {options.map((x, index) => (
          <Button
            w={"100%"}
            key={index}
            onClick={() => {
              select(x);
              setSelected(x);
            }}
            // style={{
            //   backgroundColor: x === selected ? "main.maroon" : "",
            // }}
            backgroundColor={x === selected ? "main.teal" : ""}
            _hover={{
              backgroundColor: "highlight.teal",
            }}
          >
            {x}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
};
