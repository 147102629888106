import { createIcon } from "@chakra-ui/react";

export const CardSelectIcon = createIcon({
  displayName: "CardSelectIcon",
  viewBox: "0 0 512 512",
  path: (
    <path
      fill="currentColor"
      d="M386.7,487.8l-119.2-55.4c-7.9-3.7-11.3-13.1-7.7-21l84.4-181.5c3.7-7.9,13.1-11.3,21-7.7l119.2,55.4
      c7.9,3.7,11.3,13.1,7.7,21l-84.4,181.5C404,488,394.6,491.4,386.7,487.8L386.7,487.8z M98.9,467.9L19.8,284.1
      c-3.4-8,0.3-17.3,8.2-20.7l39.2-16.9l49,184c4.7,17.6,22.8,28.1,40.4,23.4l39-10.4l-75.9,32.7C111.7,479.7,102.4,476,98.9,467.9
      L98.9,467.9z M132.8,426.1L81.4,232.6c-2.2-8.4,2.8-17.1,11.2-19.3l34-9.1l-4.1,30.5c-2.4,18,10.2,34.7,28.3,37.1l79.8,10.8
      l-32.5,109.5c-3.3,11.3-0.4,22.9,6.9,31.2l-52.8,14C143.7,439.5,135.1,434.5,132.8,426.1L132.8,426.1z M241.3,421.3l-16-4.8
      c-8.4-2.5-13.1-11.3-10.6-19.6l57-191.9c2.5-8.4,11.3-13.1,19.6-10.6l49.1,14.6l0,0l0.1,0l-0.1,0c-4.9,3.3-9,7.9-11.7,13.6
      l-84.4,181.5C241.6,409.7,240.7,415.6,241.3,421.3L241.3,421.3z M235.6,265.9l-82.5-11.1c-8.6-1.2-14.7-9.1-13.5-17.8l26.7-198.4
      c1.2-8.6,9.1-14.7,17.7-13.5l130.3,17.6c8.6,1.2,14.7,9.1,13.5,17.8l-16.4,122.1l-15.3-4.6c-17.5-5.2-35.8,4.8-41,22.2L235.6,265.9
      L235.6,265.9z"
    />
  ),
});
