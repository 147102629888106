enum WSMessageType {
  LOG = "LOG",
  FULL_LOG = "FULL_LOG",
  INPUT = "INPUT",
  REQUEST = "REQUEST",
  UPDATE = "UPDATE",
  GAME_STATE = "GAME_STATE",
  //TODO remove choice_context and replace with UPDATE
  CHOICE_CONTEXT = "CHOICE_CONTEXT",
  GAME_CONTEXT = "GAME_CONTEXT",
  ROOM = "ROOM",
  CONTROLLER = "CONTROLLER",
  NOTIFICATION = "NOTIFICATION",
}
export default WSMessageType;
