import { GridItem, SimpleGrid, Stack } from "@chakra-ui/react";
import { useState } from "react";
import { OriginSelectionButton } from ".";
import useWindowDimensions from "ui/hooks/useWindowDimensions";
import { Origin } from "game-data";
import { replaceNameTags } from "../../utils/text-utils";

interface OriginSelectionProps {
  origins: Origin[];
  pronoun: "he" | "she" | "they";
  selectedOrigin: Origin | null;
  onSelect: (origin: Origin) => void;
}
export const OriginSelection = ({
  pronoun,
  origins,
  selectedOrigin,
  onSelect,
}: OriginSelectionProps) => {
  const { width } = useWindowDimensions();
  const originButtons = origins.map((origin: Origin, index) => {
    return (
      <GridItem key={index}>
        <OriginSelectionButton
          pronoun={pronoun}
          selected={selectedOrigin === origin}
          onSelect={onSelect}
          origin={origin}
        />
      </GridItem>
    );
  });
  return (
    <Stack>
      <SimpleGrid
        spacing={5}
        columns={Math.floor(width / 400)}
        borderColor={"blue.500"}
        borderWidth={2}
        borderRadius={3}
        boxShadow={"base"}
        overflowY={"scroll"}
        height={"50vh"}
      >
        {originButtons}
      </SimpleGrid>
    </Stack>
  );
};
