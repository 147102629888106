export function replaceNameTags(
  text: string,
  pronoun: "he" | "she" | "they",
  playerName: string
): string {
  let _text = text
    .replaceAll("<Name>", playerName)
    .replaceAll("<name>", playerName);

  var he = pronoun;
  var heWas = pronoun === "they" ? "they were" : `${pronoun} was`;
  var his = pronoun === "they" ? "their" : pronoun === "she" ? "her" : "his";
  var him = pronoun === "they" ? "them" : pronoun === "she" ? "her" : "him";
  var hes =
    pronoun === "they" ? "they're" : pronoun === "she" ? "she's" : "he's";
  var heIs = pronoun === "they" ? "they are" : `${pronoun} is`;
  _text = _text
    .replaceAll("<he>", he)
    .replaceAll("<his>", his)
    .replaceAll("<him>", him)
    .replaceAll("<he's>", hes)
    .replaceAll("<hes>", hes)
    .replaceAll("<he was>", heWas)
    .replaceAll("<he is>", heIs)
    .replaceAll("<He>", capitalizeFirstLetter(he))
    .replaceAll("<His>", capitalizeFirstLetter(his))
    .replaceAll("<Him>", capitalizeFirstLetter(him))
    .replaceAll("<He's>", capitalizeFirstLetter(hes))
    .replaceAll("<Hes>", capitalizeFirstLetter(hes))
    .replaceAll("<He was>", capitalizeFirstLetter(heWas))
    .replaceAll("<He is>", capitalizeFirstLetter(heIs));

  return _text;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
