import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Center,
  GridItem,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useWindowDimensions } from "ui/hooks";
import ColorSelection, { Color } from "../input-components/ColorSelection";
import { useEffect, useState, useRef } from "react";
import { OriginSelection } from "../game-object-views";
import { useConnectionContext } from "../contexts/ConnectionContext";
import CollapsableHeading from "../base/CollapsableHeading";
import { SelectOneButton } from "../selection-components";
import { Origin } from "game-data";
import { MdCheckBox, MdIndeterminateCheckBox } from "react-icons/md";
const CharacterCreationPanel = () => {
  const defaultSelectionProcess = {
    originSelection: false,
    appearanceSelection: false,
  };
  const [selectionStep, setSelectionStep] = useState(defaultSelectionProcess);
  const {
    roomStatus: roomInfo,
    sendButtonInput: sendWSButtonInput,
    choiceContext,
  } = useConnectionContext();
  const [pronoun, setPronoun] = useState<"he" | "she" | "they">("he");
  const { isMobile, width } = useWindowDimensions();
  const [selectedOrigin, setSelectedOrigin] = useState<any>(null);
  const [subSceneType, setSubSceneType] = useState<string>("");
  const [origins, setOrigins] = useState<any[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [checkList, setCheckList] = useState<
    { name: string; checked: boolean }[]
  >([
    { name: "Pronoun", checked: false },
    { name: "Origin", checked: false },
    { name: "Appearance", checked: false },
    { name: "Colors", checked: false },
  ]);

  const cancelRef = useRef(null);
  useEffect(() => {
    if (choiceContext) {
      console.log(choiceContext);
      if (choiceContext.choices) {
        const _origins = choiceContext.choices
          .filter((x: any) => x.choiceContext === "origin")
          .map((x: any) => x.data);
        setOrigins(_origins);
      } else {
        console.log("No player choices yet");
      }
    }
  }, [choiceContext]);

  const toast = useToast();
  // const origins = [{ name: "Mountain Folk", description: "Suited to a harsh life in the mountains, <Player> has learned to be more resilient than the average adventurer.", possibleTraits: ["Adventurous", "Burly", "C"] },
  // { name: "Disgruntled Monk ", description: "<Player> likes prayer!" }]
  //const subSceneType = gameContext ? gameContext.subSceneType : null
  enum CharacterCreationStages {
    ORIGIN_PHYSICAL,
    ORIGIN_CLASS,
    APPEARANCE,
    COLORS,
    READY,
  }

  const primaryColors: Color[] = [
    {
      r: 200,
      g: 150,
      b: 50,
      a: 1,
    },
    {
      r: 200,
      g: 50,
      b: 50,
      a: 1,
    },
    {
      r: 155,
      g: 155,
      b: 255,
      a: 1,
    },
    {
      r: 50,
      g: 50,
      b: 150,
      a: 1,
    },
    {
      r: 50,
      g: 150,
      b: 50,
      a: 1,
    },
    {
      r: 150,
      g: 150,
      b: 150,
      a: 1,
    },
    {
      r: 100,
      g: 100,
      b: 50,
      a: 1,
    },
    {
      r: 50,
      g: 150,
      b: 50,
      a: 1,
    },
  ];
  const tertiaryColors: Color[] = [
    {
      r: 180,
      g: 140,
      b: 50,
      a: 1,
    },
    {
      r: 180,
      g: 45,
      b: 45,
      a: 1,
    },
    {
      r: 125,
      g: 125,
      b: 180,
      a: 1,
    },
    {
      r: 45,
      g: 45,
      b: 125,
      a: 1,
    },
    {
      r: 45,
      g: 120,
      b: 45,
      a: 1,
    },
    {
      r: 180,
      g: 160,
      b: 45,
      a: 1,
    },
  ];
  const skinColors: Color[] = [
    {
      r: 141,
      g: 85,
      b: 36,
      a: 1,
    },
    {
      r: 198,
      g: 134,
      b: 66,
      a: 1,
    },
    {
      r: 224,
      g: 172,
      b: 105,
      a: 1,
    },
    {
      r: 241,
      g: 194,
      b: 125,
      a: 1,
    },
    {
      r: 255,
      g: 219,
      b: 172,
      a: 1,
    },
  ];

  const _expanded = {
    bg: "blue.400",
    color: "white",
  };
  function changeChecklistItem(name: string) {
    var _checkList = checkList;
    _checkList.forEach((x) => {
      if (x.name === name) {
        x.checked = true;
      }
    });
    setCheckList(_checkList);
  }
  function sendColor(fieldName: string, color: Color) {
    sendWSButtonInput(
      "character_selection",
      fieldName,
      `${color.r / 255},${color.g / 255},${color.b / 255},${color.a}`
    );
    changeChecklistItem("Colors");
  }
  function selectPronoun(pronoun: "he" | "she" | "they") {
    sendWSButtonInput("character_selection", "pronoun", pronoun);
    setPronoun(pronoun);
    changeChecklistItem("Pronoun");
  }

  function selectOrigin(origin: Origin) {
    setSelectedOrigin(origin);
    console.log("Selected origin: ", origin);
    sendWSButtonInput("character_selection", "origin", origin.name);
    changeChecklistItem("Origin");
  }

  const appearanceButtonStyle = {
    fontFamily: "clear",
    fontSize: 25,
    width: "100%",
    letterSpacing: "0.05em",
  };
  function appearanceSelection() {
    return (
      <Box>
        <SimpleGrid spacing={4} columns={Math.floor(width / 200)}>
          <GridItem>
            <Button
              onClick={() => {
                sendWSButtonInput("character_selection", "face", "head_shape");
              }}
              {...appearanceButtonStyle}
            >
              Head Shape
            </Button>
          </GridItem>
          <GridItem>
            <Button
              onClick={() => {
                sendWSButtonInput("character_selection", "face", "hair_front");
              }}
              {...appearanceButtonStyle}
            >
              Front Hair
            </Button>
          </GridItem>

          <GridItem>
            <Button
              onClick={() => {
                sendWSButtonInput("character_selection", "face", "hair_main");
              }}
              {...appearanceButtonStyle}
            >
              Main Hair
            </Button>
          </GridItem>
          <GridItem>
            <Button
              onClick={() => {
                sendWSButtonInput("character_selection", "face", "brows");
              }}
              {...appearanceButtonStyle}
            >
              Brows
            </Button>
          </GridItem>
          <GridItem>
            <Button
              onClick={() => {
                sendWSButtonInput("character_selection", "face", "eyes");
              }}
              {...appearanceButtonStyle}
            >
              Eyes
            </Button>
          </GridItem>

          <GridItem>
            <Button
              onClick={() => {
                sendWSButtonInput("character_selection", "face", "iris");
              }}
              {...appearanceButtonStyle}
            >
              Pupils
            </Button>
          </GridItem>
          <GridItem>
            <Button
              onClick={() => {
                sendWSButtonInput("character_selection", "face", "ears");
              }}
              {...appearanceButtonStyle}
            >
              Ears
            </Button>
          </GridItem>
          <GridItem>
            <Button
              onClick={() => {
                sendWSButtonInput("character_selection", "face", "nose");
              }}
              {...appearanceButtonStyle}
            >
              Nose
            </Button>
          </GridItem>
          <GridItem>
            <Button
              onClick={() => {
                sendWSButtonInput("character_selection", "face", "mouth");
              }}
              {...appearanceButtonStyle}
            >
              Mouth
            </Button>
          </GridItem>
        </SimpleGrid>
      </Box>
    );
  }
  function colorSelection() {
    return (
      <Stack spacing={5}>
        <CollapsableHeading
          text={"Skin Color"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <ColorSelection
            colors={skinColors}
            swatches={isMobile ? 1 : -1}
            submitColorChange={(color) => sendColor(`color:skin`, color)}
            lightnessSlider
          />
        </CollapsableHeading>

        <CollapsableHeading text={"Primary Color"}>
          <ColorSelection
            colors={primaryColors}
            swatches={isMobile ? 1 : -1}
            submitColorChange={(color) => sendColor(`color:primary`, color)}
            colorSlider
            lightnessSlider
          />
        </CollapsableHeading>

        <CollapsableHeading text={"Secondary Color"}>
          <ColorSelection
            colors={primaryColors}
            swatches={isMobile ? 1 : -1}
            submitColorChange={(color) => sendColor(`color:secondary`, color)}
            colorSlider
            lightnessSlider
          />
        </CollapsableHeading>

        <CollapsableHeading text={"Tertiary Color"}>
          <ColorSelection
            colors={tertiaryColors}
            swatches={isMobile ? 1 : -1}
            submitColorChange={(color) => sendColor(`color:tertiary`, color)}
            colorSlider
            lightnessSlider
          />
        </CollapsableHeading>

        <CollapsableHeading text={"Eye Color"}>
          <ColorSelection
            colors={tertiaryColors}
            swatches={isMobile ? 1 : -1}
            submitColorChange={(color) => sendColor(`color:iris`, color)}
            colorSlider
            lightnessSlider
          />
        </CollapsableHeading>
        <CollapsableHeading text={"Primary Hair Color"}>
          <ColorSelection
            colors={primaryColors}
            swatches={isMobile ? 1 : -1}
            submitColorChange={(color) => sendColor(`color:hair`, color)}
            colorSlider
            lightnessSlider
          />
        </CollapsableHeading>
        <CollapsableHeading text={"Secondary Hair Color"}>
          <ColorSelection
            colors={tertiaryColors}
            swatches={isMobile ? 1 : -1}
            submitColorChange={(color) =>
              sendColor(`color:secondary_hair`, color)
            }
            colorSlider
            lightnessSlider
          />
        </CollapsableHeading>
      </Stack>
    );
  }

  function finalCheck() {
    if (selectedOrigin == null) {
      return false;
    }
    //can include other checks here
    return true;
  }

  return (
    <>
      <Center>
        <Heading>Character Creation</Heading>
      </Center>
      {/* <DynamicChecklist items={checkList} /> */}
      <Accordion allowToggle p={4} bg={"blackAlpha.200"}>
        <SimpleGrid columns={1}>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={_expanded}>
                <CheckListItem checkList={checkList} name={"Pronoun"} />
                <Box as="span" flex="1" textAlign="left">
                  Pronoun Selection
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <SelectOneButton
                label="How are you referred to?"
                options={["he", "she", "they"]}
                select={(val) => {
                  selectPronoun(val as "he" | "she" | "they");
                }}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={_expanded}>
                <CheckListItem checkList={checkList} name={"Origin"} />
                <Box as="span" flex="1" textAlign="left">
                  Origin Selection
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <OriginSelection
                onSelect={selectOrigin}
                selectedOrigin={selectedOrigin}
                pronoun={pronoun}
                origins={origins}
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _expanded={_expanded}>
                <CheckListItem checkList={checkList} name={"Appearance"} />
                <Box as="span" flex="1" textAlign="left">
                  Appearance Selection
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} pl={1}>
              {appearanceSelection()}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _expanded={_expanded}>
                <CheckListItem checkList={checkList} name={"Colors"} />
                <Box as="span" flex="1" textAlign="left">
                  Color Selection
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} pl={1}>
              <Text fontFamily={"clear"} textAlign={"center"}>
                Note that at some values of lightness, the color may appear
                black or white (or extremes of one hue)
              </Text>
              {colorSelection()}
            </AccordionPanel>
          </AccordionItem>
        </SimpleGrid>
      </Accordion>
      <Center>
        <Button w={"100%"} p={4} onClick={onOpen}>
          Ready
        </Button>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Confirm Character
              </AlertDialogHeader>

              <AlertDialogBody>
                {finalCheck() ? (
                  <Text>
                    Are you sure? It'll be sometime before you can change your
                    character again.
                  </Text>
                ) : (
                  <Text>You still need to select an origin.</Text>
                )}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Wait, I'm not ready
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    onClose();
                    sendWSButtonInput("character_selection", "ready", "ready");
                    console.log(selectedOrigin);
                  }}
                  ml={3}
                >
                  I'm ready!
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Center>
    </>
  );
};

export default CharacterCreationPanel;

function CheckListItem(props: {
  checkList: { name: string; checked: boolean }[];
  name: string;
}) {
  const { checkList, name } = props;
  return (
    <Icon
      bg="white"
      borderRadius={5}
      mr={1}
      fontSize={"20px"}
      as={
        checkList.find((x) => x.name === name)?.checked
          ? MdCheckBox
          : MdIndeterminateCheckBox
      }
      color={
        checkList.find((x) => x.name === name)?.checked
          ? "green.400"
          : "red.400"
      }
    />
  );
}
