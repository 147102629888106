import { ListItemProps } from "@chakra-ui/react";
import { Tooltip, Icon, Text } from "@chakra-ui/react";
import { useWindowDimensions } from "ui/hooks";
import { GlossaryTerm } from "./glossary-term";
import { useGlossaryContext } from "./GlossaryContext";

interface GlossaryTermDisplayProps {
  term: GlossaryTerm;
  text?: string;
}
export function GlossaryTermDisplay({ term, text }: GlossaryTermDisplayProps) {
  const { isMobile } = useWindowDimensions();
  const { inspectTerm, onlyColorIcons } = useGlossaryContext();
  const { name, description, tooltip, icon, color } = term;
  return (
    <Tooltip hasArrow label={tooltip} fontSize="16px">
      <Text
        onClick={() => {
          inspectTerm(term);
          console.log(term);
        }}
        as="b"
        fontWeight={"normal"}
        color={onlyColorIcons ? "" : color}
        textDecoration={isMobile ? "underline" : ""}
        _hover={{
          cursor: "pointer",
          textDecoration: "underline",
          textDecorationColor: color,
        }}
      >
        {text} <Icon as={icon} color={color} />
      </Text>
    </Tooltip>
  );
}
