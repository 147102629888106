"use client";
import {
  useDisclosure,
  Box,
  BoxProps,
  Button,
  Collapse,
  Heading,
  Icon,
  HStack,
  Spacer,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { useWindowDimensions } from "ui/hooks";
import { HiOutlineChevronUp, HiOutlineChevronDown } from "react-icons/hi";
export default function CollapsibleSection(
  props: BoxProps & {
    title?: string;
    children: React.ReactNode;
    fontFamily?: string;
    depth?: number;
    titleFontSize?: number;
  }
) {
  //TODO add support for dark theme
  const { colorMode } = useColorMode();
  const { isOpen: open, onToggle: toggle } = useDisclosure();
  const { children, depth } = props;
  const { isMobile } = useWindowDimensions();
  const { titleFontSize } = props;
  return (
    <Box
      width={"100%"}
      // boxShadow={"1px 4px 5px 3px rgba(0,0,0,0.25)"}
      borderTopRadius={5}
    >
      <Button
        p={1}
        pl={5}
        // colorScheme={"brandPrimary"}
        h="full"
        // bg={"white"}
        width={"100%"}
        fontSize={titleFontSize ? `${titleFontSize}px` : "xl"}
        fontFamily={props?.fontFamily}
        onClick={toggle}
        transition="font-size 0.4s ease-out"
        borderWidth="2px"
        borderBottomRadius={0}
        _hover={{
          borderColor: "blue.400",

          transition: "font-size rotate 0.4s ease-out",
        }}
        borderColor={open ? "brandPrimary.400" : ""}
      >
        <Text whiteSpace={"break-spaces"} h="full">
          {props?.title}
        </Text>{" "}
        <Spacer width={"10px"} />
        <Icon
          as={HiOutlineChevronDown}
          style={{ rotate: open ? "-180deg" : "0deg" }}
          transition="rotate 0.4s ease-out"
        />
      </Button>
      <Collapse in={open} animateOpacity>
        <Box
          {...props}
          justifyContent="center"
          p={5}
          // bg={"brandPrimary.100"}
        >
          {children}{" "}
        </Box>
      </Collapse>{" "}
    </Box>
  );
}
