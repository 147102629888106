export enum ControllerComponentType {
  FOLDOUT = "FOLDOUT",
  HEADING = "HEADING",
  TEXT = "TEXT",
  BUTTON = "BUTTON",
  CYCLE_BUTTONS = "CYCLE_BUTTONS",
  COLOR_INPUT = "COLOR_INPUT",
  TEXT_INPUT = "TEXT_INPUT",
  OBJECT_SELECTION = "OBJECT_SELECTION",
  NUMBER_INPUT = "NUMBER_INPUT",
  CONTAINER = "CONTAINER",
  FORM = "FORM",
}
