import { HStack, IconButton } from "@chakra-ui/react";
import { useGlossaryContext } from "../glossary";
import { ConnectionControls } from "./connection-controls";
import { GiBookmarklet, GiBookmark } from "react-icons/gi";
export default function MainToolbar() {
  const { inspectTerm } = useGlossaryContext();
  return (
    <HStack>
      <ConnectionControls icon={true} />
      <IconButton
        onClick={() => {
          inspectTerm();
        }}
        aria-label="Open the Glossary"
        icon={<GiBookmark />}
      />
    </HStack>
  );
}
