import { List, ListItem, ListIcon } from "@chakra-ui/react";
import { ListProps } from "@chakra-ui/styled-system";
import { ReactNode, useEffect, useState } from "react";
import {
  glossary,
  GlossaryTerm,
  GlossaryTermDisplay,
  useGlossaryContext,
} from ".";

const tagMatchRegex =
  /<(?<tag>[a-zA-Z_]+)>(?<content>[a-zA-Z0-9 .!?"-]+)<\/(?:\k<tag>)>|<(?<solo>[a-zA-Z_]+)+\/>/gm;
export function Glossary(props: ListProps & { h?: string | number }) {
  const [glossaryItems, setGlossaryItems] = useState<GlossaryTerm[]>([]);
  useEffect(() => {
    var items: GlossaryTerm[] = [];
    for (var term in glossary) {
      items.push(glossary[term]);
    }
    setGlossaryItems(items);
  }, [glossary]);

  return (
    <List
      overflowY="scroll"
      bg="blackAlpha.400"
      p={4}
      h={props.h}
      spacing="10px"
    >
      {glossaryItems.map((entry, index) => (
        <GlossaryIndexItem key={index} entry={entry} />
      ))}
    </List>
  );
}
function GlossaryIndexItem({
  key,
  entry,
}: {
  key: React.Key | undefined | null;
  entry: GlossaryTerm;
}) {
  const { inspectTerm, onlyColorIcons } = useGlossaryContext();
  return (
    <ListItem
      fontSize={"26px"}
      key={key}
      fontFamily="glossary"
      _hover={{
        textDecoration: "underline",
        textDecorationColor: entry.color,
        cursor: "pointer",
      }}
      onClick={() => {
        inspectTerm(entry);
      }}
      color={onlyColorIcons ? "" : entry.color}
    >
      <ListIcon as={entry.icon} color={entry.color} fontSize="45px" />
      {entry.name}
    </ListItem>
  );
}

export function parseGlossaryTerms(
  description: string
  // inspectProperty: (property: string) => void
) {
  const parsedDescription: ReactNode[] = [];
  const matches = description.matchAll(tagMatchRegex);
  let match = matches.next();
  let lastIndex = 0;
  while (!match.done) {
    //console.log(match.value);
    var normalText = description.substring(lastIndex, match.value.index);
    parsedDescription.push(normalText);
    lastIndex = match.value.index! + match.value[0].length;
    //there is only content if there is tag
    var content = match.value.groups?.content;
    var tag = match.value.groups?.tag;
    var solo = match.value.groups?.solo;

    if (!tag && solo) {
      switch (solo) {
        case "br":
          parsedDescription.push(<br />);
          break;
        default:
          // var soloKey = solo.toLowerCase() as keyof typeof glossary;
          var soloTerm = glossary[solo];
          var soloText = solo;
          if (soloTerm) {
            var soloText = soloTerm.name;
          } else {
            soloTerm = glossary.default;
          }

          parsedDescription.push(
            <GlossaryTermDisplay
              key={match.value.index}
              term={soloTerm}
              text={soloText}
            />
          );
          break;
      }
    } else if (tag) {
      // var key = tag.toLowerCase() as keyof typeof glossary;
      var term = glossary[tag];

      if (!term) {
        term = glossary.default;
      }
      parsedDescription.push(
        <GlossaryTermDisplay
          key={match.value.index}
          term={term}
          text={content}
        />
      );
    }
    match = matches.next();
  }
  parsedDescription.push(description.substring(lastIndex));
  return parsedDescription;
}
