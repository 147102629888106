import { Input, InputProps } from "@chakra-ui/react";

interface InputTextProps extends InputProps {
  isActive: boolean;
  label: string;
  value: string;
  setValue: (value: string) => void;
  isUpper?: boolean;
}
function InputText({
  isActive = true,
  label,
  value,
  setValue,
  isUpper = true,
  colorScheme,
}: InputTextProps) {
  return (
    <Input
      colorScheme={colorScheme || "gold"}
      placeholder={label}
      value={value}
      onChange={(e) => {
        if (isActive) {
          if (isUpper) setValue(e.target.value.toUpperCase());
          else setValue(e.target.value);
        }
      }}
      isDisabled={!isActive}
    />
  );
}

export default InputText;
