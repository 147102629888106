import {
  border,
  Box,
  Button,
  Collapse,
  HStack,
  IconButton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Left, Right } from "../DirectionalButtons";
import { BsFillBrightnessHighFill } from "react-icons/bs";
import { IoColorPaletteSharp } from "react-icons/io5";
import { MdFormatColorReset } from "react-icons/md";
import useWindowDimensions from "ui/hooks/useWindowDimensions";
export type Color = {
  r: number;
  g: number;
  b: number;
  a: number;
};
interface ColorSelectionProps {
  colors: Color[];
  lightnessSlider?: boolean;
  colorSlider?: boolean;
  swatches?: number;
  submitColorChange: (color: Color) => void;
}
const ColorSelection = (props: ColorSelectionProps) => {
  const { width } = useWindowDimensions();
  const {
    colors,
    submitColorChange: submitColorChange,
    lightnessSlider,
    colorSlider,
  } = props;
  let sideColors = 1;
  if (props.swatches && props.swatches < 0) {
    sideColors = Math.floor(Math.min(width / 210, colors.length / 2));
  } else {
    sideColors = props.swatches || 1;
  }

  const [index, setIndex] = useState(0);
  const [currentColor, setCurrentColor] = useState(colors.at(0));
  const [currentAdjustment, setCurrentAdjustment] = useState<Color>({
    r: 0,
    g: 0,
    b: 0,
    a: 0,
  });
  const { isOpen: openRGB, onToggle: toggleRGB } = useDisclosure();
  const { isOpen: openLightness, onToggle: toggleLightness } = useDisclosure();
  const [throttle, setThrottle] = useState(false);

  useEffect(() => {
    const _color = colors.at(index);
    if (_color) {
      const _edittedColor: Color = getEdittedColor(_color);
      setCurrentColor(_edittedColor);
    }
  }, [index, currentAdjustment]);

  useEffect(() => {
    if (currentColor) {
      onColorChange(currentColor);
    }
  }, [currentColor]);
  function onColorChange(_color: Color) {
    if (throttle) {
      return;
    } else {
      submitColorChange(_color);
      //console.log(`submitted color! ${_color}`)
      setThrottle(true);
      setTimeout(() => {
        setThrottle(false);
      }, 300);
    }
  }

  function setColorIndex(value: number) {
    if (value >= colors.length) {
      setIndex(value - colors.length);
    } else if (value < 0) {
      setIndex(colors.length + value);
    } else {
      setIndex(value);
    }
  }

  function showSwatches() {
    let swatches = [];
    for (var i = index - sideColors; i <= index + sideColors; i++) {
      let spot = i;
      if (i < 0) {
        spot = colors.length + i;
      } else if (i >= colors.length) {
        spot = i - colors.length;
      }
      const color = colors.at(spot);
      if (color)
        swatches.push(
          <ColorSwatch
            key={i}
            color={getEdittedColor(color)}
            selected={i == index}
            index={i}
            selectIndex={setColorIndex}
          />
        );
    }
    return swatches;
  }

  function getEdittedColor(_color: Color): Color {
    return {
      r: _color.r + currentAdjustment.r + currentAdjustment.a,
      g: _color.g + currentAdjustment.g + currentAdjustment.a,
      b: _color.b + currentAdjustment.b + currentAdjustment.a,
      a: 1,
    };
  }

  function adjustColor(value: number, channel: "r" | "g" | "b" | "a") {
    setCurrentAdjustment({ ...currentAdjustment, [channel]: value });
  }
  return (
    <Box>
      <HStack justifyContent={"center"}>
        <Left
          action={() => {
            setColorIndex(index - 1);
          }}
        />
        {showSwatches()}
        <Right
          action={() => {
            setColorIndex(index + 1);
          }}
        />
      </HStack>
      <HStack justifyContent={"center"}>
        {(colorSlider || lightnessSlider) && (
          <ColorSwatch
            color={getEdittedColor(colors[index])}
            selected={true}
            index={index}
            size={20}
          />
        )}
        {colorSlider && (
          <IconButton
            aria-label={"edit color"}
            icon={<IoColorPaletteSharp />}
            onClick={toggleRGB}
            bg={openRGB ? "blue.400" : "highlight.100"}
            color={openRGB ? "white" : "black"}
          />
        )}
        {lightnessSlider && (
          <IconButton
            aria-label={"edit lightness"}
            icon={<BsFillBrightnessHighFill />}
            onClick={toggleLightness}
            bg={openLightness ? "blue.400" : "highlight.100"}
            color={openLightness ? "white" : "black"}
          />
        )}
        {(lightnessSlider || colorSlider) && (
          <IconButton
            aria-label={"reset color adjustments"}
            icon={<MdFormatColorReset />}
            onClick={() => {
              setCurrentAdjustment({ r: 0, g: 0, b: 0, a: 0 });
            }}
          />
        )}
      </HStack>
      <Collapse in={openLightness}>
        <Slider
          size="lg"
          min={-64}
          max={64}
          defaultValue={0}
          onChange={(value) => {
            adjustColor(value, "a");
          }}
          value={currentAdjustment.a}
        >
          <SliderTrack bg={"white"} height={"10px"} borderWidth={1}>
            <SliderFilledTrack bg={"black"} />
          </SliderTrack>
          <SliderThumb as={BsFillBrightnessHighFill} color={"black"} />
        </Slider>
      </Collapse>
      <Collapse in={openRGB} animateOpacity>
        {
          /**Red slider*/
          colorSlider && (
            <Box
              display={"inline-flex"}
              w="100%"
              justifyContent={"space-between"}
            >
              <Slider
                w="30%"
                size="lg"
                min={-64}
                max={64}
                defaultValue={0}
                onChange={(value) => {
                  adjustColor(value, "r");
                }}
                value={currentAdjustment.r}
              >
                <SliderTrack
                  bg={"white"}
                  height={"10px"}
                  borderWidth={1}
                  borderColor={"blue"}
                >
                  <SliderFilledTrack bg={"red"} />
                </SliderTrack>
                <SliderThumb as={BsFillBrightnessHighFill} color={"black"} />
              </Slider>
              <Slider
                w={"30%"}
                size="lg"
                min={-64}
                max={64}
                defaultValue={0}
                onChange={(value) => {
                  adjustColor(value, "g");
                }}
                value={currentAdjustment.g}
              >
                <SliderTrack
                  bg={"white"}
                  height={"10px"}
                  borderWidth={1}
                  borderColor={"blue"}
                >
                  <SliderFilledTrack bg={"green"} />
                </SliderTrack>
                <SliderThumb as={BsFillBrightnessHighFill} color={"black"} />
              </Slider>

              <Slider
                w={"30%"}
                size="lg"
                min={-64}
                max={64}
                defaultValue={0}
                onChange={(value) => {
                  adjustColor(value, "b");
                }}
                value={currentAdjustment.b}
              >
                <SliderTrack
                  bg={"white"}
                  height={"10px"}
                  borderWidth={1}
                  borderColor={"blue"}
                >
                  <SliderFilledTrack bg={"blue"} />
                </SliderTrack>
                <SliderThumb as={BsFillBrightnessHighFill} color={"black"} />
              </Slider>
            </Box>
          )
        }
      </Collapse>
    </Box>
  );
};

function ColorSwatch({
  color,
  selected,
  index,
  selectIndex,
  size,
}: {
  color: Color;
  selected: boolean;
  index: number;
  selectIndex?: (index: number) => void;
  size?: number;
}) {
  size = size || 50;
  return (
    <Button
      key={index}
      onClick={() => {
        if (selectIndex) {
          selectIndex(index);
          //console.log(`set index to ${index}"`);
        }
      }}
      _active={{}}
      _hover={{ bg: createColor(color), border: "5px solid white" }}
      borderColor={selected ? "whiteAlpha.700" : ""}
      borderWidth={selected ? "5px" : ""}
      borderRadius={10}
      w={selected ? `${size + 10}px` : `${size}px`}
      h={selected ? `${size + 10}px` : `${size}px`}
      bg={createColor(color)}
    ></Button>
  );
}

function createColor(color: Color): string {
  return `rgba(${color.r},${color.g},${color.b},${color.a})`;
}
export default ColorSelection;
