import {
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  Center,
  Heading,
  HStack,
  Icon,
  IconButton,
  Img,
  Text,
  Tooltip,
  BoxProps,
  useBoolean,
} from "@chakra-ui/react";
import { match } from "assert";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { GiMagnifyingGlass } from "react-icons/gi";
import { useWindowDimensions } from "ui/hooks";
import { getAbilityIconURL } from "../../config";
import { CollapsableHeading } from "../base";
import { parseGlossaryTerms } from "../glossary";
interface AbilityCardProps extends BoxProps {
  index: number;
  selectedIndex?: number;
  rotation?: number;
  offsetX?: number;
  /**How much does this card overlap the card on its left? */
  overlapX?: number;
  offsetY?: number;

  ability: any;
  onHover?: (state: boolean) => void;
  select?: (index: number) => void;
  showInspectButton?: boolean;
  //TODO turn into ABILITY
}
const imageBorderRadius = "35px";

export function AbilityCard1({
  ability,
  offsetY: offset,
  rotation,
}: AbilityCardProps) {
  const imagePath = getAbilityIconURL(ability.image);
  return (
    <Box
      style={{
        translate: `0px ${offset}px`,
        rotate: `${rotation}deg`,
      }}
      w="350px"
      h="500"
      borderWidth={"10px"}
      borderColor="blackAlpha.500"
      padding={"20px"}
      bg="blueGray.200"
      borderRadius={"45px"}
      justifyContent="center"
      justifyItems={"center"}
      justifySelf="center"
    >
      {/* <CollapsableHeading text={ability.name}> */}
      <Heading
        textStyle={"ability-header"} //fontFamily="body"
      >
        {ability.name.toUpperCase()}
      </Heading>

      <Box
        borderRadius={imageBorderRadius}
        borderWidth={"2px"}
        borderColor="blackAlpha.500"
        w="-webkit-fit-content"
        ml={"auto"}
        mr="auto"
      >
        <Img
          borderRadius={imageBorderRadius}
          maxW="200px"
          src={imagePath}
          ml="auto"
          mr="auto"
          justifySelf={"center"}
        />
      </Box>
      <Box mt="5" bg="blackAlpha.700" borderRadius={"5"} p={5}>
        <Text fontFamily="ability" fontSize="20px">
          {parseGlossaryTerms(ability.description)}
        </Text>
      </Box>
      {/* </CollapsableHeading> */}
    </Box>
  );
}

export function AbilityCard2({
  ability,
  offsetY: offset,
  rotation,
}: AbilityCardProps) {
  const imagePath = getAbilityIconURL(ability.image);
  return (
    <Box
      style={{
        translate: `0px ${offset}px`,
        rotate: `${rotation}deg`,
      }}
      w="350px"
      h="500"
      borderWidth={"10px"}
      borderColor="blackAlpha.500"
      // padding={"20px"}
      bg="blueGray.200"
      borderRadius={"45px"}
      justifyContent="center"
      justifyItems={"center"}
      justifySelf="center"
      p="12.5px"
      transition={"all 0.5s ease"}
      _hover={{
        borderColor: "gold.500",
        transition: "all 0.5s ease",
        //transform: "translate(0px,-15px)",
      }}
      // onMouseEnter={() => {
      //   console.log("hovering");
      // }}
      // onMouseLeave={() => {
      //   console.log("hovering");
      // }}
    >
      {/* <CollapsableHeading text={ability.name}> */}

      <Box
        bgImage={imagePath}
        borderRadius={imageBorderRadius}
        borderWidth={"2px"}
        borderColor="blackAlpha.500"
        bgAttachment={"center"}
        bgSize={"cover"}
        bgRepeat={"no-repeat"}
        w="300px"
        h="300px"
        ml={"auto"}
        mr="auto"
        p={3}
        alignItems={"center"}
      >
        {" "}
        <Heading
          h="100%"
          textAlign={"center"}
          pt={"45%"}
          fontFamily="ability"
          fontSize={"50px"}
        >
          {ability.name.toUpperCase()}
        </Heading>
      </Box>
      <Box mt="5" bg="blackAlpha.700" borderRadius={"5"} p={5}>
        <Text fontFamily={"ability"} fontSize={"20px"}>
          {parseGlossaryTerms(ability.description)}
        </Text>
      </Box>
      {/* </CollapsableHeading> */}
    </Box>
  );
}

export function AbilityCard4({
  ability,
  rotation,
  index,
  offsetY: offset,
}: // key,
AbilityCardProps) {
  const [hovering, setHovering] = useBoolean();
  const [throttle, setThrottle] = useBoolean();
  // const [inspect, setInspect] = useBoolean(false);
  const imagePath = getAbilityIconURL(ability.image);
  let timer: ReturnType<typeof setTimeout>;
  function toggleHover(state: boolean) {
    if (!state) {
      setHovering.off();
      return;
    }

    if (throttle) return;
    //if hovering, we are allowed to instantly leave
    setThrottle.on();
    setHovering.on();
    timer = setTimeout(() => {
      setThrottle.off();
    }, 500);
  }
  //TODO finish throttling
  return (
    <Box
      key={index}
      overflow={"hidden"}
      position={"relative"}
      style={{
        translate: `0px ${offset}px`,
        rotate: `${rotation}deg`,
      }}
      w="350px"
      h="500px"
      borderWidth={"10px"}
      borderColor="blueGray.50"
      // padding={"20px"}
      bgImage="linear-gradient(150deg,blueGray.200,blueGray.100)"
      transition={"all 0.5s ease"}
      bgRepeat={"no-repeat"}
      bgSize={"cover"}
      _hover={{
        bgImage: "linear-gradient(150deg,gold.500,blueGray.200,blueGray.100)",
        transform: "translate(0px,-15px)",
      }}
      borderRadius={"45px"}
      justifyContent="center"
      justifyItems={"center"}
      justifySelf="center"
      onPointerEnter={() => {
        toggleHover(true);
      }}
      onPointerLeave={() => {
        toggleHover(false);
      }}
    >
      {/* <CollapsableHeading text={ability.name}> */}
      <Box
        mt={"20px"}
        borderRadius={imageBorderRadius}
        borderWidth={"2px"}
        borderColor="blackAlpha.500"
        w="-webkit-fit-content"
        ml={"auto"}
        mr="auto"
      >
        <Img
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "/abilities/missing_ability.png";
          }}
          transition={"all 0.5s ease"}
          borderRadius={imageBorderRadius}
          maxW={hovering ? "350px" : "200px"}
          src={imagePath}
          ml="auto"
          mr="auto"
          justifySelf={"center"}
        />
      </Box>
      <Box
        bg="blackAlpha.700"
        borderRadius={"5"}
        p={5}
        position={"absolute"}
        transition={"all 0.3s ease-out"}
        top={hovering ? "20px" : "300px"}
        w="100%"
      >
        <Heading textStyle={"ability-header"} fontFamily="ability">
          {ability.name.toUpperCase()}
        </Heading>
      </Box>
      <Box
        overflowY={"scroll"}
        bg="blackAlpha.700"
        opacity={hovering ? "100%" : "0%"}
        transition={"all 0.3s ease-in-out"}
        transitionDelay={hovering ? "0.2s" : "0s"}
        position={"absolute"}
        top={"150px"}
        h="100%"
      >
        <Text
          overflowY={"scroll"}
          fontFamily="ability"
          fontSize="20px"
          mt="5"
          borderRadius={"5"}
          p={5}
          h="150px"
        >
          {parseGlossaryTerms(ability.description)}
        </Text>
      </Box>
      {/* <Text
        fontFamily="ability"
        fontSize="20px"
        overflowY={"scroll"}
        mt="5"
        bg="blackAlpha.700"
        borderRadius={"5"}
        p={5}
        opacity={hovering ? "100%" : "0%"}
        transition={"all 0.3s ease-in-out"}
        transitionDelay={hovering ? "0.2s" : "0s"}
        position={"absolute"}
        top={"150px"}
        h="60%"
      >
        {parseGlossaryTerms(ability.description)}
      </Text> */}

      <IconButton
        top={2}
        right={2}
        position={"absolute"}
        variant={"ghost"}
        icon={<GiMagnifyingGlass />}
        aria-label={"Inspect ability"}
        transition={"all 0.5s ease"}
        colorScheme={"white"}
        onClick={() => {}}
        _hover={{
          fontSize: "30px",
          transition: "all 0.7s ease",
        }}
      />
    </Box>
  );
}

export function AbilityCard({
  ability,
  rotation,
  index,
  selectedIndex,
  overlapX,
  offsetY,
  onHover,
  select,
  zIndex,
  showInspectButton,
}: AbilityCardProps) {
  const [hovering, setHovering] = useBoolean();
  const [throttle, setThrottle] = useBoolean();
  if (overlapX === undefined) overlapX = 0;
  if (offsetY === undefined) offsetY = 0;
  if (rotation === undefined) rotation = 0;
  // const [inspect, setInspect] = useBoolean(false);
  const imagePath = getAbilityIconURL(ability.image);
  let timer: ReturnType<typeof setTimeout>;
  const { isMobile } = useWindowDimensions();
  function toggleHover(state: boolean) {
    if (!state) {
      // setHovering.off();
      onHover?.(state);
      return;
    }

    if (throttle) return;
    //if hovering, we are allowed to instantly leave
    setThrottle.on();
    // setHovering.on();
    onHover?.(state);
    timer = setTimeout(() => {
      setThrottle.off();
    }, 500);
  }
  useEffect(() => {
    //console.log("SelectedIndex", selectedIndex);
    if (selectedIndex === index) {
      setHovering.on();
    } else {
      setHovering.off();
    }
  }, [selectedIndex]);

  let moveX = !selectedIndex
    ? 0
    : selectedIndex === index
    ? 0
    : selectedIndex < index
    ? overlapX
    : selectedIndex > index
    ? -overlapX
    : 0;
  //TODO finish throttling
  return (
    <Box
      zIndex={hovering ? 100 : zIndex ? zIndex : "auto"}
      ml={`-${overlapX}px`}
      key={index}
      overflow={"hidden"}
      position={"relative"}
      style={{
        translate: `${moveX}px ${hovering ? 0 : offsetY}px`,
        rotate: `${hovering ? 0 : rotation}deg`,
      }}
      minW="250px"
      minH="400px"
      maxW="250px"
      maxH="400px"
      borderWidth={"10px"}
      borderColor={hovering ? "gold.500" : "blueGray.50"}
      // padding={"20px"}
      bgImage={
        hovering
          ? "linear-gradient(180deg,gold.700,gold.500,blueGray.200 25%,blueGray.100)"
          : "linear-gradient(150deg,blueGray.200,blueGray.100)"
      }
      transition={"all 0.5s ease"}
      bgRepeat={"no-repeat"}
      bgSize={"cover"}
      borderRadius={"45px"}
      justifyContent="center"
      justifyItems={"center"}
      justifySelf="center"
      onPointerEnter={() => {
        toggleHover(true);
      }}
      onPointerLeave={() => {
        toggleHover(false);
      }}
      onClick={() => {
        select?.(index);
      }}
    >
      {/* <CollapsableHeading text={ability.name}> */}
      <Box
        position={"relative"}
        borderBottomWidth={hovering ? "0px" : "10px"}
        borderColor="blackAlpha.500"
        w="-webkit-fit-content"
        ml={hovering ? "5px" : "auto"}
        mt={hovering ? "5px" : "auto"}
        mr="auto"
        transition={"all 0.5s ease"}
        maxW={hovering ? "80px" : "280px"}
        zIndex={hovering ? "overlay" : "auto"}
        overflow={"hidden"}
        boxShadow="xl"
        borderRadius={hovering ? "full" : imageBorderRadius}
      >
        <Img
          borderRadius={hovering ? "full" : imageBorderRadius}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "/abilities/missing_ability.png";
          }}
          //borderRadius={imageBorderRadius}
          src={imagePath}
          ml={"auto"}
          mr="auto"
          justifySelf={"center"}
        />
      </Box>
      <Box
        bg="blackAlpha.700"
        borderRadius={"5"}
        p={5}
        position={"absolute"}
        transition={"all 0.2s ease-out"}
        top={hovering ? "20px" : "300px"}
        w="100%"
        h="80px"
      >
        <Heading
          ml={hovering ? "70px" : "auto"}
          textStyle={"ability-header"}
          fontFamily="ability"
          fontSize={hovering ? "20px" : "30px"}
          transition={"all 0.1s ease"}
        >
          {ability.name.toUpperCase()}
        </Heading>
      </Box>
      <Box
        overflowY={"scroll"}
        bg="blackAlpha.700"
        opacity={hovering ? "100%" : "0%"}
        transition={"all 0.3s ease-in-out"}
        transitionDelay={hovering ? "0.2s" : "0s"}
        position={"absolute"}
        top={"120px"}
        h="220px"
      >
        {hovering && (
          <Text fontFamily="ability" fontSize="20px" borderRadius={"5"} p={5}>
            {parseGlossaryTerms(ability.description)}
          </Text>
        )}
      </Box>

      {showInspectButton && (
        <IconButton
          top={2}
          right={2}
          position={"absolute"}
          variant={"ghost"}
          icon={<GiMagnifyingGlass />}
          aria-label={"Inspect ability"}
          transition={"all 0.5s ease"}
          colorScheme={"white"}
          onClick={() => {
            setHovering.toggle();
          }}
          _hover={{
            fontSize: "30px",
            transition: "all 0.7s ease",
          }}
        />
      )}
    </Box>
  );
}
