import { Choice } from "server-types";
import { InputComponentProps } from ".";
import { useConnectionContext } from "../contexts/ConnectionContext";
import { Box, Button, Text } from "@chakra-ui/react";
import TraitDisplay from "./TraitDisplay";
import { CollapsibleSection } from "ui/base-components";
import { replaceNameTags } from "../../utils/text-utils";

interface OriginSelectionComponentProps extends InputComponentProps {
  pronoun?: "he" | "she" | "they";
}
export default function OriginSelectionComponent({
  choiceContext,
  sendChoice,
  pronoun = "he",
}: OriginSelectionComponentProps) {
  const { playerName } = useConnectionContext();
  function showTraits(originChoice: Choice) {
    if (originChoice.data?.traits) {
      const list: any[] = JSON.parse(originChoice.data.traits);
      return (
        <Box>
          <Text> Traits </Text>
          {list.map((trait, i) => {
            return <TraitDisplay trait={trait} key={i} />;
          })}
        </Box>
      );
    }
  }
  return (
    <CollapsibleSection title="Select An Origin">
      {choiceContext &&
        choiceContext.choices.map((choice, i) => {
          return (
            <Box key={i}>
              <CollapsibleSection title={choice.display?.title}>
                {choice.display?.description && (
                  <Text>
                    {replaceNameTags(
                      choice.display.description,
                      pronoun,
                      playerName
                    )}
                  </Text>
                )}
                {showTraits(choice)}
                <Button
                  onClick={() => {
                    sendChoice(choice);
                  }}
                >
                  {"Select"}
                </Button>
              </CollapsibleSection>
            </Box>
          );
        })}
    </CollapsibleSection>
  );
}
