import {
  Box,
  Button,
  Collapse,
  GridItem,
  HStack,
  IconButton,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Choice,
  ChoiceContext,
  ColorInputControllerComponent,
} from "server-types";
import { useWindowDimensions } from "ui/hooks";
import { Color } from "../../types";
import { useEffect, useState } from "react";
import { CollapsibleSection } from "ui/base-components";
import { BsFillBrightnessHighFill } from "react-icons/bs";
import { IoColorPaletteSharp } from "react-icons/io5";
import { MdFormatColorReset } from "react-icons/md";
import InputComponentProps from "./InputComponentProps";
interface ColorInputComponentProps extends InputComponentProps {
  size?: number;

  component: ColorInputControllerComponent;
}
export default function ColorInputComponent({
  choiceContext,
  sendChoice,
  size = 50,
  component,
}: ColorInputComponentProps) {
  const { isMobile, width } = useWindowDimensions();
  const [r, setR] = useState(0);
  const [g, setG] = useState(0);
  const [b, setB] = useState(0);
  const [a, setA] = useState(1);
  const [currentColor, setCurrentColor] = useState<Color | null>(null);
  const [presetColors, setPresetColors] = useState<Color[]>([]);
  const { isOpen: openRGB, onToggle: toggleRGB } = useDisclosure();
  useEffect(() => {
    setPresetColors(createPresets(choiceContext));
  }, []);
  const { rgbIsEditable } = component;
  function createPresets(choiceContext: ChoiceContext) {
    if (!choiceContext) return [];
    const presetColors: Color[] = [];
    choiceContext.choices.forEach((choice, i) => {
      if (
        choice.data &&
        choice.data.r &&
        choice.data.g &&
        choice.data.b &&
        choice.data.a
      ) {
        presetColors.push(
          new Color(
            choice.data.r * 256,
            choice.data.g * 256,
            choice.data.b * 256,
            choice.data.a
          )
        );
      }
    });
    return presetColors;
  }

  return (
    <Box justifyContent={"center"}>
      <Text>{choiceContext.context}</Text>
      <SimpleGrid
        columns={!isMobile && presetColors?.length > 0 ? 2 : 1}
        spacing={4}
      >
        <GridItem>
          <HStack justifyContent={"center"}>
            {(currentColor || rgbIsEditable) && (
              <Box
                bg={`rgba(${r},${g},${b},${a})`}
                w={`${size + 10}px`}
                h={`${size + 10}px`}
                borderRadius={10}
              />
            )}
            {rgbIsEditable && (
              <IconButton
                aria-label={"edit color"}
                icon={<IoColorPaletteSharp />}
                onClick={toggleRGB}
                bg={openRGB ? "blue.400" : "highlight.100"}
                color={openRGB ? "white" : "black"}
              />
            )}
            <Button
              // w={"100%"}
              onClick={() => {
                const color = new Color(r, g, b, a);
                sendChoice({
                  choiceID: "color",
                  context: choiceContext.context,

                  data: color.toFloatColor(),
                });
              }}
            >
              {"Submit"}
            </Button>
          </HStack>
        </GridItem>
        {presetColors.length > 0 && (
          <GridItem>
            <CollapsibleSection title={"Presets"}>
              <SimpleGrid
                spacing={4}
                columns={Math.floor(
                  (isMobile ? width : width / 2) / (size * 2)
                )}
              >
                {presetColors.map((color, i) => {
                  return (
                    <GridItem>
                      <ColorSwatch
                        color={color}
                        size={size}
                        onClick={(color: Color) => {
                          setR(color.r);
                          setG(color.g);
                          setB(color.b);
                          setA(color.a);
                          setCurrentColor(color);
                        }}
                      />
                    </GridItem>
                  );
                })}
              </SimpleGrid>
            </CollapsibleSection>
          </GridItem>
        )}
      </SimpleGrid>

      <Collapse in={openRGB} animateOpacity>
        {
          /**Color sliders*/
          rgbIsEditable && (
            <Stack
              spacing={"25px"}
              pt="10px"
              pb="10px"
              direction={isMobile ? "column" : "row"}
              w="100%"
              justifyContent={"space-between"}
            >
              <Slider
                // w="30%"
                size="lg"
                min={0}
                max={255}
                defaultValue={0}
                onChange={setR}
                value={r}
              >
                <SliderTrack
                  bg={"white"}
                  height={"10px"}
                  borderWidth={1}
                  borderColor={"blue"}
                >
                  <SliderFilledTrack bg={"red"} />
                </SliderTrack>
                <SliderThumb as={BsFillBrightnessHighFill} color={"black"} />
              </Slider>
              <Slider
                // w={"30%"}
                size="lg"
                min={0}
                max={255}
                onChange={setG}
                value={g}
              >
                <SliderTrack
                  bg={"white"}
                  height={"10px"}
                  borderWidth={1}
                  borderColor={"blue"}
                >
                  <SliderFilledTrack bg={"green"} />
                </SliderTrack>
                <SliderThumb as={BsFillBrightnessHighFill} color={"black"} />
              </Slider>

              <Slider
                // w={"30%"}
                size="lg"
                min={0}
                max={255}
                onChange={setB}
                value={b}
              >
                <SliderTrack
                  bg={"white"}
                  height={"10px"}
                  borderWidth={1}
                  borderColor={"blue"}
                >
                  <SliderFilledTrack bg={"blue"} />
                </SliderTrack>
                <SliderThumb as={BsFillBrightnessHighFill} color={"black"} />
              </Slider>
            </Stack>
          )
        }
      </Collapse>
    </Box>
  );
}

function ColorSwatch({
  color,
  size,
  onClick,
}: {
  color: Color;
  size?: number;
  onClick: (color: Color) => void;
}) {
  size = size || 50;
  return (
    <Button
      onClick={() => {
        onClick(color);
      }}
      _active={{}}
      _hover={{ bg: color.toRGBAString(), border: "5px solid white" }}
      borderRadius={10}
      w={`${size}px`}
      h={`${size}px`}
      bg={color.toRGBAString()}
    />
  );
}
