import { Button, GridItem, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { Choice, ChoiceContext } from "server-types";
import { useWindowDimensions } from "ui/hooks";
import { InputComponentProps } from ".";

interface ButtonInputComponentProps extends InputComponentProps {}
export default function ButtonInputComponent({
  choiceContext,
  sendChoice,
}: ButtonInputComponentProps) {
  const { isMobile, width } = useWindowDimensions();
  if (!choiceContext) {
    return <Text> No Choice Context</Text>;
  }
  return (
    <SimpleGrid spacing={4} columns={isMobile ? 2 : 3}>
      {choiceContext.choices.map((choice, i) => {
        return (
          <GridItem key={i}>
            <Button
              whiteSpace={"pre-line"}
              w="100%"
              height={"100%"}
              onClick={() => {
                sendChoice(choice);
              }}
            >
              <Stack h={"100%"} p="2" w="100%">
                <Text>
                  {choice.display ? choice.display.title : choice.choiceID}
                </Text>
                {choice.display?.description && (
                  <Text fontSize={"xs"}> {choice.display?.description}</Text>
                )}
              </Stack>
            </Button>
          </GridItem>
        );
      })}
    </SimpleGrid>
  );
}
