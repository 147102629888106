import {
  Box,
  Button,
  ButtonGroup,
  GridItem,
  Img,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { Ability } from "game-data";
import { useState } from "react";
import { useWindowDimensions } from "ui/hooks";
import { getAbilityIconURL } from "../../config";
import { AbilityCard } from "./AbilityCard";
interface AbilityDeckViewProps {
  abilities: Ability[];
  onAbilitySelect: (ability: Ability) => void;
  viewingDiscard?: boolean;
}
export default function AbilityDeckView({
  abilities,
  onAbilitySelect,
  viewingDiscard,
}: AbilityDeckViewProps) {
  const { width, isMobile } = useWindowDimensions();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  return (
    <Box>
      {!isMobile && (
        <Box maxH="80vh" overflow={"scroll"}>
          <SimpleGrid columns={Math.floor((width - 100) / 250)}>
            {abilities.map((ability, index) => {
              return (
                <GridItem key={index}>
                  <AbilityCard
                    ability={ability}
                    key={index}
                    index={index}
                    selectedIndex={selectedIndex}
                    showInspectButton
                    select={(index: number) => {
                      if (selectedIndex === index) {
                        setSelectedIndex(undefined);
                      } else {
                        setSelectedIndex(index);
                      }
                    }}
                  />
                </GridItem>
              );
            })}
          </SimpleGrid>
        </Box>
      )}
      {isMobile && (
        <Box pt="20px" justifyContent={"center"}>
          <Stack h="50vh" overflow={"scroll"}>
            {abilities.map((ability, index) => {
              return (
                <Button
                  w="90%"
                  h="35px"
                  key={index}
                  onClick={() => {
                    if (selectedIndex === index) {
                      setSelectedIndex(undefined);
                    } else {
                      setSelectedIndex(index);
                    }
                  }}
                  justifyContent={"flex-start"}
                >
                  <Img
                    src={getAbilityIconURL(ability.image)}
                    w="30px"
                    borderRadius={"full"}
                  />
                  <Box w="20px" />
                  {ability.name}
                </Button>
              );
            })}
          </Stack>{" "}
          {selectedIndex !== undefined && (
            <AbilityCard
              ability={abilities[selectedIndex]}
              index={selectedIndex}
              selectedIndex={selectedIndex}
            />
          )}
        </Box>
      )}
    </Box>
  );
}
