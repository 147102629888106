import { useGameControllerContext } from "../contexts/GameControllerContext";
import { useState, useRef, useEffect, useCallback } from "react";
import {
  Button,
  Stack,
  Grid,
  GridItem,
  HStack,
  Input,
  List,
  ListItem,
  ListIcon,
  useToast,
  Box,
  Checkbox,
} from "@chakra-ui/react";
import { AiOutlineAlert, AiOutlineUser } from "react-icons/ai";
import { RiZzzFill } from "react-icons/ri";
import { LogMessage, LogMessageType, WSMessageType } from "server-types";

import { useConnectionContext } from "../contexts/ConnectionContext";
import { useWindowDimensions } from "ui/hooks";
export function LogDisplay() {
  const {
    sendChatMessage,
    roomStatus: roomInfo,
    wsEventEmitter,
  } = useConnectionContext();
  const [text, setText] = useState<string>("");
  const toast = useToast();
  const myRef = useRef(null);
  const { height, isMobile } = useWindowDimensions();
  const [fullLog, setFullLog] = useState<LogMessage[]>([]);
  const [showTime, setShowTime] = useState<boolean>(false);
  const [showActions, setShowActions] = useState<boolean>(false);
  const endOfList = useRef<HTMLDivElement | null>(null);
  const receiveNewLogMessage = useCallback(
    (log: LogMessage) => {
      // Create a new log entry and update your state here
      // For example, if fullLog is an array of log messages:
      setFullLog((prevlogs) => [...prevlogs, log]);
      endOfList.current?.scrollIntoView({ behavior: "smooth" });
    },
    [fullLog]
  );
  const clearLog = useCallback(() => {
    setFullLog([]);
  }, []);
  useEffect(() => {
    wsEventEmitter.on(WSMessageType.LOG, receiveNewLogMessage);
    wsEventEmitter.on("close", clearLog);
    // Clean up the listener when the component unmounts
    return () => {
      wsEventEmitter.off(WSMessageType.LOG, receiveNewLogMessage);
      wsEventEmitter.off("close", clearLog);
    };
  }, [wsEventEmitter]);
  function formatLog(log: LogMessage, key: number) {
    if (!log.data) {
      return;
    }
    const { logMessageType, text } = log.data;
    const str = `${log.sender}: ${text}`;

    switch (logMessageType) {
      case LogMessageType.ACTION:
        if (!showActions) {
          return;
        }
        return (
          <ListItem key={key}>
            <ListIcon as={AiOutlineAlert} color="gray.300" />
            {str}
          </ListItem>
        );
      default:
        return (
          <ListItem key={key}>
            {showTime &&
              `[${new Date(log.time).toLocaleString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}]`}
            <ListIcon as={AiOutlineUser} />
            {str}
          </ListItem>
        );
    }
  }

  function sendMessage() {
    if (text) {
      if (!roomInfo) {
        toast({
          title: "Haven't joined a room!",
          description: "You've got nobody to talk to.",
          status: "warning",
        });
        return;
      }

      sendChatMessage(text, "");
      setText("");
    }
  }
  return (
    <Box h="70vh">
      <HStack justifyContent={"space-evenly"}>
        <Checkbox
          colorScheme={"govy"}
          onChange={() => {
            setShowTime(!showTime);
          }}
        >
          {"Show Time"}
        </Checkbox>
        <Checkbox
          colorScheme={"govy"}
          onChange={() => {
            setShowActions(!showActions);
          }}
        >
          {"Show Time"}
        </Checkbox>

        <Button
          colorScheme={"govy"}
          onClick={() => {
            clearLog();
          }}
        >
          Clear Log
        </Button>
      </HStack>
      <List overflowY={"scroll"} h="80%">
        {fullLog.length > 0 ? (
          fullLog.map(formatLog)
        ) : (
          <ListItem>
            <ListIcon as={RiZzzFill} />
            No messages
          </ListItem>
        )}
        <div ref={endOfList} style={{ height: "20px" }} />
      </List>
      <HStack
        bottom={0}
        position={"fixed"}
        mb={5}
        left={0}
        right={0}
        justifyContent={"center"}
        ml={5}
        mr={5}
      >
        <Input
          isDisabled={!roomInfo}
          type="text"
          onChange={(e) => {
            setText(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
          placeholder="Type Here"
          value={text}
        />
        <Button
          colorScheme={"govy"}
          onClick={sendMessage}
          isDisabled={!roomInfo}
        >
          Send
        </Button>
      </HStack>
    </Box>
  );
}
