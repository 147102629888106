import { useEffect, useState } from "react";
import { Button, ButtonGroup, useToast, HStack, Stack } from "@chakra-ui/react";
import { useConnectionContext } from "../../contexts/ConnectionContext";
import { InputText } from "ui/base-components";
export function RoomControls({
  orientation = "vertical",
}: {
  orientation?: "horizontal" | "vertical";
}) {
  const { joinRoom, leaveRoom, roomStatus, setPlayerName, playerName } =
    useConnectionContext();
  const [roomCode, setRoomCode] = useState<string>("");
  const wsProtocol = window.location.protocol === "https:" ? "wss:" : "ws:";
  const wsAddress = `${wsProtocol}//${window.location.host}`;
  const [url, setUrl] = useState<string>(wsAddress);
  const toast = useToast();
  useEffect(() => {
    const prevName = localStorage.getItem("playerName");
    if (prevName) {
      setPlayerName(prevName);
    } else {
      setPlayerName("");
    }
    const prevCode = localStorage.getItem("roomCode");
    if (prevCode) {
      setRoomCode(prevCode);
    } else {
      setRoomCode("");
    }
  }, []);

  return (
    <ButtonGroup orientation={"vertical"} spacing={2}>
      <Stack w={"100"} direction={orientation == "vertical" ? "column" : "row"}>
        <InputText
          value={playerName}
          setValue={setPlayerName}
          isActive={!roomStatus}
          label="NAME"
        />
        <InputText
          value={roomCode}
          setValue={setRoomCode}
          isActive={!roomStatus}
          label="ROOM CODE"
        />
      </Stack>
      <InputText
        isUpper={false}
        value={url}
        setValue={setUrl}
        isActive={!roomStatus}
        label="URL (optional)"
      />
      <Button
        w={"100%"}
        onClick={() => {
          if (!roomCode) {
            toast({
              variant: "subtle",
              position: "top-right",
              title: "Missing Room Code",
              status: "error",
              duration: 1000,
            });
            return;
          }
          if (!playerName) {
            toast({
              variant: "subtle",
              position: "top-right",
              title: "Missing Player Name",
              status: "error",
              duration: 1000,
            });
            return;
          }

          if (roomStatus) {
            leaveRoom();
          } else {
            joinRoom(roomCode, url);
          }
        }}
      >
        {roomStatus ? "Leave Room" : "Join Room"}
      </Button>
    </ButtonGroup>
  );
}
