import {
  useDisclosure,
  Box,
  BoxProps,
  Button,
  Collapse,
  Heading,
  Icon,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import { HiOutlineChevronUp, HiOutlineChevronDown } from "react-icons/hi";
export default function CollapsableHeading(
  props: BoxProps & {
    text?: string;
    children: React.ReactNode;
    fontFamily?: string;
  }
) {
  const { isOpen: open, onToggle: toggle } = useDisclosure();
  const { children } = props;
  return (
    <Box width={"100%"}>
      <Button
        width={"100%"}
        as={Heading}
        fontSize={"xl"}
        fontFamily={props?.fontFamily}
        onClick={toggle}
        color={open ? "white" : ""}
        bg={open ? "base.50" : ""}
        _active={{ bg: "base.50" }}
        _hover={{ bg: "base.50" }}
      >
        {props?.text} <Spacer width={"10px"} />
        <Icon as={open ? HiOutlineChevronUp : HiOutlineChevronDown} />
      </Button>

      <Collapse in={open} animateOpacity>
        <Box {...props} justifyContent="center">
          {children}
        </Box>
      </Collapse>
    </Box>
  );
}
