import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

// const baseStyle = definePartsStyle({
//   // define the part you're going to style
//   field: {
//     borderBottomRightRadius: "full",
//     fontFamily: "note", // change the font family
//     color: "white", // change the input text color
//     _placeholder: {
//       color: "gray.50", // change the placeholder color
//     },
//   },
// });
const baseStyle = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return {
    // define the part you're going to style
    field: {
      letterSpacing: "wider",
      bg: "transparent",
      outline: "transparent",
      transitionProperty: "common",
      transitionDuration: "normal",
      borderRadius: "5",
      //fontFamily: "note", // change the font family
      color: "white", // change the input text color
      _placeholder: {
        color: "whiteAlpha.700", // change the placeholder color
      },
    },
  };
});
const variantMenu = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return {
    field: {
      borderWidth: "2px",
      borderColor: `${c}.300`,
      color: "white",
      _hover: {
        borderColor: `${c}.500`,
      },
      _focus: {
        borderColor: `${c}.400`,
      },
    },
  };
});

const variants = {
  menu: variantMenu,
};
export const inputStyle = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: "menu",
  },
});
