import { CombatControlsPanel } from "../combat_controls/CombatControlsPanel";
import { useGameControllerContext } from "../contexts/GameControllerContext";
import {
  CharacterCreationPanel,
  LocationPanel,
  WorldMapControlDisplay,
} from "../default-controller-panels";

const MainControlPanel = () => {
  const { currentControllerPanel } = useGameControllerContext();
  const { characterCreation, location, combat, worldMap } =
    currentControllerPanel;
  return (
    <>
      {characterCreation && <CharacterCreationPanel />}
      {worldMap && <WorldMapControlDisplay />}
      {location && <LocationPanel />}
      {combat && <CombatControlsPanel />}
    </>
  );
};
export default MainControlPanel;
