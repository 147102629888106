import {
  Box,
  Heading,
  IconButton,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Ability } from "game-data";
import { AbilityCardHandFan, AbilityCardHandFlat } from "./AbilityCardHand";
import AbilityDeckView from "./AbilityDeckView";
import { GiCardPick, GiCardDraw, GiCardBurn } from "react-icons/gi";
import { useWindowDimensions } from "ui/hooks";
import { useEffect, useState } from "react";
import { BsFillGrid3X2GapFill } from "react-icons/bs";
import { CardSelectIcon } from "ui/icons";
interface AbilitySelectionDisplayProps {
  abilitiesInHand: Ability[];
  abilitiesInDeck: Ability[];
  abilitiesInDiscard: Ability[];
}
export default function AbilitySelectionDisplay({
  abilitiesInDeck,
  abilitiesInDiscard,
  abilitiesInHand,
}: AbilitySelectionDisplayProps) {
  const { isMobile, width, height } = useWindowDimensions();
  const buttonHeight = isMobile ? "60px" : "100px";
  const [viewState, setViewState] = useState<"hand" | "deck" | "discard">(
    "hand"
  );
  const [handView, setHandView] = useState<"fan" | "flat">("fan");
  const [selectedAbility, setSelectedAbility] = useState<Ability>();

  useEffect(() => {
    console.log(selectedAbility);
  }, [selectedAbility]);
  return (
    <>
      <Heading textAlign={"center"} fontFamily="ability">
        {viewState === "deck"
          ? `Abilities in Deck (${abilitiesInDeck.length})`
          : viewState === "hand"
          ? `Abilities in Hand (${abilitiesInHand.length})`
          : `Used Abilities (${abilitiesInDiscard.length})`}
      </Heading>
      <Box display={isMobile ? "" : "flex"} alignItems="center">
        <Stack direction={isMobile ? "row" : "column"}>
          {viewState !== "hand" && (
            <Tooltip label={"View Abilities available this turn"}>
              <IconButton
                h={buttonHeight}
                aria-label={"View abilties available this turn"}
                fontSize={buttonHeight}
                icon={<CardSelectIcon />}
                onClick={() => {
                  setViewState("hand");
                }}
              />
            </Tooltip>
          )}
          {viewState !== "deck" && (
            <Tooltip label={"View all your Abilities"}>
              <IconButton
                h={buttonHeight}
                aria-label={"View all abilities"}
                fontSize={buttonHeight}
                icon={<BsFillGrid3X2GapFill />}
                onClick={() => {
                  setViewState("deck");
                }}
              />
            </Tooltip>
          )}
          {viewState !== "discard" && (
            <Tooltip label={"View your used Abilities"}>
              <IconButton
                h={buttonHeight}
                aria-label={"View your used abilities"}
                fontSize={buttonHeight}
                icon={<GiCardBurn />}
                onClick={() => {
                  setViewState("discard");
                }}
              />
            </Tooltip>
          )}
          {!isMobile && viewState === "hand" && (
            <Tooltip
              label={
                handView === "flat"
                  ? "Arrange abilities in a fan"
                  : "Arrange abilities in a panel"
              }
            >
              <IconButton
                h={buttonHeight}
                aria-label="Change Hand View"
                fontSize={buttonHeight}
                icon={<GiCardPick />}
                bg={handView === "fan" ? "gold.200" : "gold.400"}
                onClick={() => {
                  handView === "fan" ? setHandView("flat") : setHandView("fan");
                }}
              />
            </Tooltip>
          )}
        </Stack>
        <Box overflow={"scroll"} w={width * 0.9}>
          {viewState === "hand" &&
            (isMobile || handView == "flat" ? (
              <AbilityCardHandFlat
                abilities={abilitiesInHand}
                onSelectAbility={setSelectedAbility}
              />
            ) : (
              <AbilityCardHandFan
                abilities={abilitiesInHand}
                onSelectAbility={setSelectedAbility}
              />
            ))}
          {viewState === "deck" && (
            <AbilityDeckView
              abilities={abilitiesInDeck}
              onAbilitySelect={() => {}}
            />
          )}{" "}
          {viewState === "discard" && (
            <AbilityDeckView
              abilities={abilitiesInDiscard}
              onAbilitySelect={() => {}}
            />
          )}
        </Box>
      </Box>
      {/* <AbilityCardHandFan
        abilities={testAbilities.slice(0, 5)}
        onSelectAbility={setSelectedAbility}
      />
      <AbilityCardHandFan
        abilities={testAbilities.slice(8, 12)}
        onSelectAbility={setSelectedAbility}
      /> */}
    </>
  );
}
