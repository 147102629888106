import {
  IconButton,
  HStack,
  Spacer,
  Stack,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { IoReorderThree } from "react-icons/io5";
import { useWindowDimensions } from "ui/hooks";
import { useConnectionContext } from "../../contexts/ConnectionContext";
import { RoomControls } from "./RoomControls";
import { WebControllerPanel } from "./WebControllerPanel";
import { IoGameController } from "react-icons/io5";
export default function ConnectionControlButton({ icon }: { icon?: boolean }) {
  const { onToggle, isOpen, onClose, onOpen } = useDisclosure();
  const { roomStatus } = useConnectionContext();
  useEffect(() => {
    onOpen();
  }, []);
  if (icon)
    return (
      <>
        <IconButton
          onClick={onToggle}
          aria-label="Room Controls"
          icon={<IoGameController />}
        />
        {roomStatus ? <Text>{roomStatus?.roomCode}</Text> : <></>}
        <ConnectionControlDrawer isOpen={isOpen} onClose={onClose} />
      </>
    );

  return (
    <>
      <Button onClick={onToggle}>
        {roomStatus ? "Room Controls" : "Room Controls"}
      </Button>
      {roomStatus ? <Text>{roomStatus?.roomCode}</Text> : <></>}
      <ConnectionControlDrawer isOpen={isOpen} onClose={onClose} />
    </>
  );
}

/**
 * Show the connection controls in a drawer
 * @param props
 * @returns
 */
export function ConnectionControlDrawer(props: {
  isOpen: boolean;
  onClose: () => void;
}) {
  //const btnRef = React.useRef<HTMLButtonElement>(null)
  const { roomStatus } = useConnectionContext();
  const { isOpen, onClose } = props;
  const { isMobile } = useWindowDimensions();
  return (
    <Drawer
      isOpen={isOpen}
      placement={isMobile ? "top" : "right"}
      onClose={onClose}
      //finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Room Controls</DrawerHeader>

        <DrawerBody>
          <Stack>
            <RoomControls orientation={isMobile ? "vertical" : "horizontal"} />
            <Spacer />
            <WebControllerPanel />
            <Box borderRadius={5} p={2} bg={"whiteAlpha.100"}>
              {roomStatus ? (
                <>
                  <Text>Some information goes here</Text>
                </>
              ) : (
                <></>
              )}
            </Box>
          </Stack>
        </DrawerBody>

        <DrawerFooter>
          <Button
            variant="ghost"
            colorScheme={"blue"}
            leftIcon={<IoReorderThree />}
            onClick={onClose}
          >
            Hide Menu
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
