import { LogDisplay } from "../log-display/LogDisplay";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  BoxProps,
  ButtonGroup,
  Button,
  Text,
  Box,
} from "@chakra-ui/react";

import {
  CharacterCreationPanel,
  WorldMapControlDisplay,
} from "../default-controller-panels";
import useWindowDimensions from "ui/hooks/useWindowDimensions";
import { useConnectionContext } from "../contexts/ConnectionContext";
import { useGameControllerContext } from "../contexts/GameControllerContext";
import MainControlPanel from "./MainControlPanel";
import RenderedControlPanel from "./RenderedControlPanel";

export const MainTabs: React.FC<BoxProps> = ({}) => {
  const { height } = useWindowDimensions();

  return (
    <Tabs isFitted colorScheme={"gold"}>
      <TabList>
        <Tab
          bg="blueGray.50"
          _hover={{
            bg: "blueGray.100",
          }}
        >
          Rendered Controller
        </Tab>
        <Tab
          bg="blueGray.50"
          _hover={{
            bg: "blueGray.100",
          }}
        >
          Controller
        </Tab>
        <Tab
          bg="blueGray.50"
          _hover={{
            bg: "blueGray.100",
          }}
        >
          Log
        </Tab>{" "}
        <Tab
          bg="blueGray.50"
          _hover={{
            bg: "blueGray.100",
          }}
        >
          Test Controls
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <RenderedControlPanel />
        </TabPanel>
        <TabPanel>
          <MainControlPanel />
        </TabPanel>
        <TabPanel>
          <LogDisplay />
        </TabPanel>
        <TabPanel>
          <TestToggleMainControlPanel />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

function TestToggleMainControlPanel() {
  const { currentControllerPanel, setCurrentControllerPanel } =
    useGameControllerContext();
  return (
    <Box
      borderColor="coral"
      borderWidth={4}
      letterSpacing="wider"
      fontWeight={"light"}
    >
      <Text fontWeight={"light"} letterSpacing="wide">
        Test Controls
      </Text>
      <ButtonGroup
        variant={"outline"}
        overflowX={"scroll"}
        maxW="100vw"
        display={"flexbox"}
      >
        <Button
          onClick={() =>
            setCurrentControllerPanel({
              location: false,
              characterCreation: false,
              combat: false,
              worldMap: false,
            })
          }
        >
          Clear
        </Button>
        <Button
          onClick={() =>
            setCurrentControllerPanel({
              ...currentControllerPanel,
              characterCreation: !currentControllerPanel.characterCreation,
            })
          }
        >
          Character Creation
        </Button>
        <Button
          onClick={() =>
            setCurrentControllerPanel({
              ...currentControllerPanel,
              combat: !currentControllerPanel.combat,
            })
          }
        >
          Combat
        </Button>
        <Button
          onClick={() =>
            setCurrentControllerPanel({
              ...currentControllerPanel,
              worldMap: !currentControllerPanel.worldMap,
            })
          }
        >
          World Map
        </Button>
        <Button
          onClick={() =>
            setCurrentControllerPanel({
              ...currentControllerPanel,
              location: !currentControllerPanel.location,
            })
          }
        >
          Location
        </Button>
      </ButtonGroup>
    </Box>
  );
}
