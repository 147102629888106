import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Center,
  Heading,
  Stack,
  Text,
  useBoolean,
} from "@chakra-ui/react";

import { Origin } from "game-data";
import { replaceNameTags } from "../../utils/text-utils";
import CollapsableHeading from "../base/CollapsableHeading";
import { useConnectionContext } from "../contexts/ConnectionContext";

interface OriginSelectionButtonProps {
  selected: boolean;
  origin: Origin;
  onSelect: (origin: Origin) => void;
  pronoun: "he" | "she" | "they";
}
export const OriginSelectionButton = (props: OriginSelectionButtonProps) => {
  const { selected, origin, onSelect, pronoun } = props;
  const { roomStatus, playerName } = useConnectionContext();
  const { name, description } = origin;

  return (
    <Stack
      spacing={0}
      bg={"blackAlpha.400"}
      p={4}
      m={2}
      borderRadius={5}
      outline={selected ? "5px solid gold" : ""}
    >
      <CollapsableHeading text={name}>
        <Text fontFamily={"clear"} letterSpacing="wide">
          {replaceNameTags(description, pronoun, playerName)}
        </Text>
      </CollapsableHeading>
      <Button
        colorScheme={"blue"}
        onClick={() => {
          onSelect(origin);
        }}
      >
        This is my origin
      </Button>
    </Stack>
  );
};
