import { Box, Button, Input } from "@chakra-ui/react";
import { useState } from "react";
import { TextInputControllerComponent } from "server-types";
import { InputComponentProps } from ".";
interface TextInputComponentProps extends InputComponentProps {
  textInput: TextInputControllerComponent;
}
export default function TextInputComponent({
  textInput,
  sendChoice,
  choiceContext,
}: TextInputComponentProps) {
  const [value, setValue] = useState("");
  return (
    <Box>
      <Input
        placeholder={textInput.placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <Button
        onClick={() => {
          sendChoice({ choiceID: value, context: choiceContext.context });
        }}
      >
        Submit
      </Button>
    </Box>
  );
}
