import { Box, Button, ButtonGroup } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Choice } from "../../../../../packages/server-types/src";
import { useConnectionContext } from "../contexts/ConnectionContext";
import DialoguePanel from "./DialoguePanel";

const LocationPanel = () => {
  //TODO change back to using global status
  // const { isHost } = useConnectionContext();
  const { sendButtonInput, choiceContext, isHost } = useConnectionContext();
  const [dialogueChoices, setDialogueChoices] = useState<Choice[]>([]);

  useEffect(() => {
    if (choiceContext?.context == "dialogue") {
      setDialogueChoices(choiceContext.choices);
    }
  }, [choiceContext]);
  return (
    <Box>
      {isHost && (
        <ButtonGroup>
          <Button onClick={() => sendButtonInput("location", "moving", "true")}>
            Move
          </Button>
          <Button
            onClick={() => sendButtonInput("location", "moving", "false")}
          >
            Stop
          </Button>
        </ButtonGroup>
      )}
      <DialoguePanel choices={dialogueChoices} />
    </Box>
  );
};
export default LocationPanel;
