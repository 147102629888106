import {
  Button,
  GridItem,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Choice, ChoiceContext, Orientation } from "server-types";
import { useWindowDimensions } from "ui/hooks";
import { InputComponentProps } from ".";
import {
  FaCaretLeft,
  FaCaretDown,
  FaCaretRight,
  FaCaretUp,
} from "react-icons/fa";
interface CycleButtonInputComponentProps extends InputComponentProps {
  orientation: Orientation;
  label: string;
}
export default function CycleButtonInputComponent({
  choiceContext,
  sendChoice,
  orientation,
  label,
}: CycleButtonInputComponentProps) {
  const { isMobile, width } = useWindowDimensions();
  if (!choiceContext) {
    return <Text> No Choice Context</Text>;
  }

  return (
    <Stack
      width={"100%"}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      direction={orientation === Orientation.HORIZONTAL ? "row" : "column"}
    >
      <IconButton
        icon={
          orientation === Orientation.HORIZONTAL ? (
            <FaCaretLeft />
          ) : (
            <FaCaretUp />
          )
        }
        onClick={() => {
          sendChoice({
            context: choiceContext.context,
            choiceID: orientation === Orientation.HORIZONTAL ? "LEFT" : "UP",
          });
        }}
        aria-label={orientation === Orientation.HORIZONTAL ? "LEFT" : "UP"}
      />
      <Text width={"100%"} textAlign={"center"}>
        {label}
      </Text>
      <IconButton
        icon={
          orientation === Orientation.HORIZONTAL ? (
            <FaCaretRight />
          ) : (
            <FaCaretDown />
          )
        }
        onClick={() => {
          sendChoice({
            context: choiceContext.context,
            choiceID: orientation === Orientation.HORIZONTAL ? "RIGHT" : "DOWN",
          });
        }}
        aria-label={orientation === Orientation.HORIZONTAL ? "RIGHT" : "DOWN"}
      />
    </Stack>
  );
}
