import { Button, ButtonGroup } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useConnectionContext } from "../../contexts/ConnectionContext";
import { useGameControllerContext } from "../../contexts/GameControllerContext";
import { InputText } from "ui/base-components";

export function WebControllerPanel({
  orientation = "vertical",
}: {
  orientation?: "horizontal" | "vertical";
}) {
  const [controllerKey, setControllerKey] = useState<string>("");
  const {
    roomStatus,
    controllerData,
    requestConnectController,
    disconnectController,
  } = useConnectionContext();

  useEffect(() => {
    if (!controllerData) {
      setControllerKey("");
    }
  }, [controllerData]);
  return (
    <ButtonGroup spacing={2} orientation={orientation}>
      <InputText
        value={controllerKey}
        setValue={setControllerKey}
        isActive={controllerData || !roomStatus ? false : true}
        label="KEY"
      />
      <Button
        colorScheme={"blue"}
        disabled={!roomStatus}
        onClick={() => {
          if (!controllerData) {
            requestConnectController(controllerKey);
          } else {
            disconnectController();
          }
        }}
      >
        {controllerData ? "Disconnect Controller" : "Connect Controller"}
      </Button>
    </ButtonGroup>
  );
}
