import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Input,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useConnectionContext } from "../contexts/ConnectionContext";
import {
  ControllerComponentType,
  FoldoutControllerComponent,
  ControllerComponent,
  HeadingControllerComponent,
  ButtonControllerComponent,
  ChoiceContext,
  Choice,
  TextInputControllerComponent,
  ObjectSelectionControllerComponent,
  ColorInputControllerComponent,
  CycleButtonControllerComponent,
} from "server-types";
import { CollapsableHeading } from "../base";
import { CollapsibleSection } from "ui/base-components";
import {
  ButtonInputComponent,
  ColorInputComponent,
  CycleButtonInputComponent,
  OriginSelectionComponent,
  TextInputComponent,
} from "../input-components";
import { useEffect, useRef, useState } from "react";

export default function RenderedControlPanel() {
  const {
    controllerLayout,
    sendChoice,
    wsEventEmitter,
    choiceContexts,
    valueStore,
  } = useConnectionContext();

  function parseControllerLayout() {
    const elements: JSX.Element[] = [];
    if (controllerLayout) {
      controllerLayout.layout.forEach((component: ControllerComponent, i) => {
        elements.push(
          <Stack key={i}>
            <Text>{component.id}</Text>
            {generateComponent(component)}
          </Stack>
        );
      });
    }
    return <Stack spacing={5}>{elements}</Stack>;
  }
  function generateComponent(component: ControllerComponent) {
    if (component.componentType === ControllerComponentType.BUTTON) {
      const buttonComponent = component as ButtonControllerComponent;
      return (
        <ButtonInputComponent
          choiceContext={choiceContexts[buttonComponent.id]}
          sendChoice={sendChoice}
        />
      );
    } else if (
      component.componentType === ControllerComponentType.CYCLE_BUTTONS
    ) {
      const cycleButtonComponent = component as CycleButtonControllerComponent;
      return (
        <CycleButtonInputComponent
          label={cycleButtonComponent.label}
          orientation={cycleButtonComponent.orientation}
          choiceContext={choiceContexts[cycleButtonComponent.id]}
          sendChoice={sendChoice}
        />
      );
    } else if (component.componentType === ControllerComponentType.TEXT_INPUT) {
      const textInput = component as TextInputControllerComponent;

      return (
        <TextInputComponent
          choiceContext={choiceContexts[component.id]}
          sendChoice={sendChoice}
          textInput={textInput}
        />
      );
    } else if (component.componentType === ControllerComponentType.FOLDOUT) {
      const foldout = component as FoldoutControllerComponent;
      return (
        <CollapsibleSection title={foldout.text}>
          {foldout.children.length > 0
            ? foldout.children.map(generateComponent)
            : "Nothing to render"}{" "}
        </CollapsibleSection>
      );
    } else if (component.componentType === ControllerComponentType.HEADING) {
      const heading = component as HeadingControllerComponent;
      return <Heading>{heading.text}</Heading>;
    } else if (
      component.componentType === ControllerComponentType.OBJECT_SELECTION
    ) {
      const objectSelection = component as ObjectSelectionControllerComponent;
      const { objectType } = objectSelection.options;
      if (objectType === "Origin") {
        return (
          <OriginSelectionComponent
            sendChoice={sendChoice}
            choiceContext={choiceContexts[component.id]}
            pronoun={
              valueStore["pronoun"]
                ? ((valueStore["pronoun"] as string).toLowerCase() as
                    | "he"
                    | "she"
                    | "they")
                : "he"
            }
          />
        );
      }
    } else if (
      component.componentType === ControllerComponentType.COLOR_INPUT
    ) {
      return (
        <ColorInputComponent
          choiceContext={choiceContexts[component.id]}
          sendChoice={sendChoice}
          component={component as ColorInputControllerComponent}
        />
      );
    }
    return (
      <Text>
        Unknown Component: Type {component.componentType}, ID: {component.id}
      </Text>
    );
  }
  return (
    <Box>
      <Text>Rendered Controller</Text>
      {controllerLayout && parseControllerLayout()}
      <Button
        onClick={() => {
          console.log(controllerLayout);
        }}
      >
        READ
      </Button>
    </Box>
  );
}

function ContextInputComponent({
  choiceContext,
}: {
  choiceContext: ChoiceContext;
}) {}
