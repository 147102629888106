/**
 * This class stores a color in RGBA format where R,G,B are 0-255 and A is 0-1
 */
export default class Color {
  r: number;
  g: number;
  b: number;
  a: number;
  constructor(r: number, g: number, b: number, a: number) {
    this.r = r;
    this.g = g;
    this.b = b;
    this.a = a;
  }
  /**
   * Create a Unity-formatted color string from the color
   * @returns an array of the color in the format [R,G,B,A] with values from 0-1
   */
  toFloatColor() {
    return { r: this.r / 255, g: this.g / 255, b: this.b / 255, a: this.a };
  }
  /**
   * Create a html-formatted color string from the color
   * @returns a string in the format "rgba(r,g,b,a)"
   */
  toRGBAString(): string {
    return `rgba(${this.r},${this.g},${this.b},${this.a})`;
  }
}
