import { Ability } from "game-data";
export const testAbilities: Ability[] = [
  {
    name: "Fire bolts",
    description:
      "Shoots three fire bolts at the enemy. Each bolt does <damage>10</damage> and applies <burn>1</burn>. <armor>10 Armor</armor> is lost",
    image: "fire_bolts_three",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
        // application: "ON_HIT",
        // damageType: "HEALTH",
      },
    ],
  },
  {
    name: "Menacing Presence",
    description:
      "A thick ephemeral fog surrounds the enemy, remove <armor>10 armor</armor> and <energy>10 energy</energy>. <br/><br/>Enemies also get <dazed/>.",
    image: "evil_fog",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
        // application: "ON_HIT",
        // damageType: "HEALTH",
      },
    ],
  },
  {
    name: "Blaze of Fury",
    description:
      "Unleash your fury on the enemy, doing <damage>10 damage</damage> and applying <burn>1</burn>.",
    image: "immolate",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
        // application: "ON_HIT",
        // damageType: "HEALTH",
      },
    ],
  },
  {
    name: "Slash",
    description:
      "Slash at the target with your weapon. Does <damage>10 damage</damage>.",
    image: "slash",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
        // application: "ON_HIT",
        // damageType: "HEALTH",
      },
    ],
  },
  {
    name: "Arcane Axe",
    description:
      "Cleave your opponents in half doing <magic>15 damage</magic> ",
    image: "magic_axe",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
        // application: "ON_HIT",
        // damageType: "HEALTH",
      },
    ],
  },
  {
    name: "Arcane Axe",
    description:
      "Cleave your opponents in half doing <magic>15 damage</magic> ",
    image: "magic_axe",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
        // application: "ON_HIT",
        // damageType: "HEALTH",
      },
    ],
  },
  {
    name: "Hack",
    description:
      "Hack at the target with your weapon. Does <damage>10 damage</damage> and applies <bleed>2</bleed>.",
    image: "axe_bloody",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
      },
    ],
  },
  {
    name: "Hack",
    description:
      "Hack at the target with your weapon. Does <damage>10 damage</damage> and applies <bleed>2</bleed>.",
    image: "axe_bloody",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
      },
    ],
  },
  {
    name: "Hack",
    description:
      "Hack at the target with your weapon. Does <damage>10 damage</damage> and applies <bleed>2</bleed>.",
    image: "axe_bloody",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
      },
    ],
  },
  {
    name: "Hack",
    description:
      "Hack at the target with your weapon. Does <damage>10 damage</damage> and applies <bleed>2</bleed>.",
    image: "axe_bloody",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
      },
    ],
  },
  {
    name: "Hack",
    description:
      "Hack at the target with your weapon. Does <damage>10 damage</damage> and applies <bleed>2</bleed>.",
    image: "axe_bloody",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
      },
    ],
  },
  {
    name: "Hack",
    description:
      "Hack at the target with your weapon. Does <damage>10 damage</damage> and applies <bleed>2</bleed>.",
    image: "axe_bloody",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
      },
    ],
  },
  {
    name: "Hack",
    description:
      "Hack at the target with your weapon. Does <damage>10 damage</damage> and applies <bleed>2</bleed>.",
    image: "axe_bloody",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
      },
    ],
  },
  {
    name: "Hack",
    description:
      "Hack at the target with your weapon. Does <damage>10 damage</damage> and applies <bleed>2</bleed>.",
    image: "axe_bloody",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
      },
    ],
  },
  {
    name: "Fire bolts",
    description:
      "Shoots three fire bolts at the enemy. Each bolt does <damage>10</damage> and applies <burn>1</burn>. <armor>10 Armor</armor> is lost",
    image: "fire_bolts_three",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
        // application: "ON_HIT",
        // damageType: "HEALTH",
      },
    ],
  },
  {
    name: "Fire bolts",
    description:
      "Shoots three fire bolts at the enemy. Each bolt does <damage>10</damage> and applies <burn>1</burn>. <armor>10 Armor</armor> is lost",
    image: "fire_bolts_three",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
        // application: "ON_HIT",
        // damageType: "HEALTH",
      },
    ],
  },
  {
    name: "Fire bolts",
    description:
      "Shoots three fire bolts at the enemy. Each bolt does <damage>10</damage> and applies <burn>1</burn>. <armor>10 Armor</armor> is lost",
    image: "fire_bolts_three",
    effects: [
      {
        name: "Damage",
        description: "Does <HEALTH_DAMAGE>5</HEALTH_DAMAGE>",
        // application: "ON_HIT",
        // damageType: "HEALTH",
      },
    ],
  },
];
